/* PrivacyPolicy.css */

.privacypolicy {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding-top: 50px;
  }
  .third-party p{
    color: #333;
    margin-bottom: 1rem;
  }

  .privacypolicy h1 {
    font-size: 28px;
    color: #333;
    font-weight: bold;
  }
  .cont-us p{
    color: #333;
    margin-bottom: 2rem;
  }
  .introduction p {
    font-size: 16px;
    color: #333;
    text-align: center;
    margin: 30px;
  }
  .privacy-summary h2 {
    font-size: 24px;
    color: #333;
    text-align: center;
  }

  .privacy-summary p {
    font-size: 16px;
    color: #333;
    text-align: left;
    margin: 30px;
  }
  
  
  .privacy-summary a {
    text-decoration: none;
    color: #3498db;
  }
  
  .information-collection h2 {
    font-size: 24px;
    color: #333;
  }
  
  .information-collection p,ul,li {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
    text-align: left;
  }
  
  .information-collection strong {
    font-weight: bold;
  }
  

  