/* Finance.css */
.Finance {
  padding: 50px;
  font-family: Arial, sans-serif;
  color: white; /* Text color */
  text-align: center;
}

.finance-block {
  background: linear-gradient(to bottom,#26a79c, #0d3835);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: auto;
  margin: 0 auto;
  border-radius: 15px; /* Curved border radius */
  padding: 20px; /* Add padding for better spacing */
}


 .finance-block .header-block h1 {
  font-size: 3rem;
  margin: 30px;
  color: #fff;
}


.finance-nav-bar ul {
  list-style: none;
  margin: 50px;
}

.finance-nav-bar li {
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 130px;
  text-align: center;
  transition: color 0.3s;
  background-color: #116C65;
}

.finance-nav-bar li:hover {
  background-color: #1ab0a4; /* Change text color on hover */
}

.Finance h2 {
  text-align: center;
  font-size: 2rem;
  line-height: 1.5;
  margin-top: 50px;
  color: #f0f0f0;
}

.finance-form-block {
  flex: 1;
  margin-left: 50px;
  margin-right: 40px;
  height: 600px; /* Fixed height for the form container */
  width: 70%;
  overflow-y: auto; /* Enable vertical scrolling inside the form container */
  background-color: #fff; /* Form container background color */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}


.image-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 600px; /* Set the maximum width of the carousel */
  margin: 20px auto; /* Center the carousel below the navbar */
  border-radius: 10px;
}

.status-data {
  overflow: auto;
  max-height: 300px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 20px;
  color: blue;
}

.status-card h2 {
  color: #116C65;
}


.requirements {
  text-align: center;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 20px 0;
  padding: 20px;
}

.requirements h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #116C65;
}

.requirements ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center;
}


.requirements li {
  font-size: 1.2rem;
  margin-bottom: 5px;
}


@media screen and (max-width: 768px) {
  .finance-block {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    align-items: center; /* Center-align items in the column */
    width: 100%;
    margin-left: -20px;
    margin-right: -10px;
  }

  .header-block h1 {
    font-size: 2.5rem; /* Decrease the header font size */
    margin: 20px; /* Adjust the margin */
  }

  .nav-bar ul {
    margin: 20px; /* Adjust the margin */
  }

  .nav-bar li {
    font-size: 1.2rem; /* Decrease the list item font size */
    margin-bottom: 8px; /* Adjust the margin */
  }

  .Finance h2 {
    font-size: 1.8rem; /* Decrease the heading font size */
    margin-top: 20px; /* Adjust the margin */
  }

  .finance-form-block {
    margin: 20px; /* Adjust the margin */
    height: auto; /* Allow the form container to expand with content */
    max-height: 400px; /* Set a maximum height */
  }
  .finance-form-block .status-card{
    margin-left: 10px;
    padding: 5px;
    margin-right: 10px;
  }
  .requirements{
    text-align: center;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 20px 0;
  padding: 20px;
  }
  .requirements h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #116C65;
    text-align: center;
    margin-left: 10px;
  }
  
  .requirements ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    text-align: center;
    margin-left: 10px;
  }
  
  
  .requirements li {
    font-size: 1.2rem;
    margin-bottom: 5px;
    text-align: center;
    margin-left: 10px;
  }
}

@media screen and (min-width: 200px) and (max-width:374px) {
  .finance-block {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    align-items: center; /* Center-align items in the column */
    width: 115%;
   margin-left: -2.3rem;
  }
}
@media screen and (width: 375px) {
  .finance-block {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    align-items: center; /* Center-align items in the column */
    width: 110%;
    margin-left: -2.2rem;
  }
}
@media screen and (width: 390px) {
  .finance-block {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    align-items: center; /* Center-align items in the column */
    width: 110%;
    margin-left: -2.2rem;
  }
}
@media screen and (min-width: 769px) and (max-width:1560px) {
  .finance-block {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    align-items: center; /* Center-align items in the column */
  }

  .header-block h1 {
    font-size: 2.5rem; /* Decrease the header font size */
    margin: 20px; /* Adjust the margin */
  }

  .nav-bar ul {
    margin: 20px; /* Adjust the margin */
  }

  .nav-bar li {
    font-size: 1.2rem; /* Decrease the list item font size */
    margin-bottom: 8px; /* Adjust the margin */
  }

  .Finance h2 {
    font-size: 1.8rem; /* Decrease the heading font size */
    margin-top: 20px; /* Adjust the margin */
  }

  .finance-form-block {
    margin: 20px; /* Adjust the margin */
    height: auto; /* Allow the form container to expand with content */
    max-height: 400px; /* Set a maximum height */
  }
}
@media screen and (max-width: 350px) {
  .Finance h2 {
    text-align: center;
    font-size: 2vh;
    line-height: 1.5;
    margin-top: 50px;
    color: #f0f0f0;
  }
  
}
