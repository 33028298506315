/* Reset some default styles */
body, h1, h2, p, form, label, input, button {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }
  
  /* Apply a global font style */
  body {
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
  }
  
  /* Container styling */
  .loan-form-container {
    max-width: 800px;
    margin: 20px auto;
    margin-top: 50px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Title styling */
  .title {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Form styling */
  .loan-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  /* Form category styling */
  .form-category {
    width: 48%; /* Adjust the width as needed */
    margin-bottom: 20px;
  }
  
  /* Input and label styling */
  .loan-label {
    display: block;
    margin-bottom: 8px;
    color: #333;
  }
  
  .loan-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  /* Button styling */
  .loan-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .loan-button:hover {
    background-color: #45a049;
  }
  
  /* Message styling */
  .success-message, .error-message {
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
  }
  
  .success-message {
    background-color: #dff0d8;
    color: #3c763d;
  }
  
  .error-message {
    background-color: #f2dede;
    color: #a94442;
  }

  