/* ReturnPlcy.css */

.terms-container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .terms-container h1 {
    color: #333;
    font-size: 2rem;
  }
  .terms-container h2 {
    text-align: left;
    margin-bottom: 20px;
    color: #333;
  }
  
  .terms-container p {
    text-align: left;
    margin-bottom: 15px;
    color: #333;
  }
  