/* NewCodeForm.css */

/* Styles for main form container */
.newcode-container {
  max-width: 100wh; /* Adjust as needed */
  margin: 0 auto;
  padding: 20px;
  border: 2px solid #1ab0a4; /* Border color */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.newcode-container h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2rem;
}

/* Styles for download container */
.download-container {
  text-align: center;
  margin-bottom: 20px;
}

.download-container div {
  display: inline-block;
  margin-right: 20px; /* Adjust as needed */
}

.download-container label {
font-weight: bold;

}

.download-container button {
    width: 100%;
    background-color: #116C65;
    color: white;
    padding: 10px 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}
.download-container button:hover {
  background-color: #1ab0a4;
}


/* Styles for individual form group containers */
.form-group-container > div {
  flex: 1; /* Each container takes up equal space */
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

/* Styles for form group container */
.form-group-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allow containers to wrap */
}


/* Styles for individual form groups */
.form-group {
  width: 100%;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

input[type="file"] {
  margin-top: 5px;
}

.submit-button {
  width: 100%;
  background-color: #116C65;
  color: white;
  padding: 10px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #1ab0a4;
}

/* Mobile view */
@media screen and (max-width: 768px) {
  .form-group-container > div {
    flex-basis: 100%; /* Each container takes full width */
  }

  .form-group-container {
    flex-direction: column; /* Containers stack vertically */
  }

  .form-container {
    max-width: 100%; /* Container takes full width */
    padding: 10px; /* Adjust padding as needed */
  }
  .newcode-container .submit-button{
    width: 85%;
  }
}
