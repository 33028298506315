/* DeleteAccount.css */

.delete-account-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 40vh;
    padding: 40px;
    background-color: #eefdf0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 0 auto;
    margin-top: 5rem;
    margin-bottom: 3rem;
}

.delete-h1 {
    font-size: 28px;
    font-weight: 700;
    color: #333;
    margin-bottom: 16px;
}

.delete-p {
    font-size: 18px;
    color: #555;
    margin-bottom: 24px;
    text-align: center;
}

.confirm-delete,.cancel-delete {
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    border-radius: 6px;
    margin: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    font-weight: 600;
}

.confirm-delete {
    background-color: #dc3545;
    color: white;
    width: 100%;
}

.confirm-delete:hover {
    background-color: #c82333;
    transform: scale(1.05);
}

.cancel-delete {
    background-color: #6c757d;
    color: white;
    width: 100%;
}

.cancel-delete:hover {
    background-color: #5a6268;
    transform: scale(1.05);
}
.instructions {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .instructions-title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
  }
  
 .instru-ctions-list {
    list-style-type: lower-alpha; 
    padding-left: 20px;
    font-size: 16px;
    color: #666;
    text-align: center;
  }
  
  .instru-ctions-list li {
    margin-bottom: 10px;
    text-align: center;
  }
  