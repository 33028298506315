/* Header.css */

.header-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: max-content;
  background-color: #ffffff;
  padding: 18px 4px 12px 4px; /* Top, Right, Bottom, Left */
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between; /* Align sections horizontally */
  align-items: center; /* Center items vertically */
}

.logo-container {
  padding: 0 20px; /* Adjust padding as needed */
}

.logo-link {
  text-decoration: none;
}

.logo {
  width: 180px; /* Adjust logo size as needed */
  height: auto;
}

.navbar-container {
  flex-grow: 1; /* Allow the navbar to grow and occupy remaining space */
  text-align: center; /* Center the navbar */
}

.login-button-container {
  padding: 0 20px; /* Adjust padding as needed */
}

/* Header.css */
.signup-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #116C65;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.signup-button:hover {
  background-color: #1ab0a4;
}


.user-icon {
  font-size: 18px; /* Adjust size as needed */
  display: inline-block;
  color: #116C65;
  border: 2px solid #116C65; /* Border color */
  border-radius: 50%; /* Make it circular */
  padding: 6px; /* Equal padding on all sides */
  transition: all 0.3s; /* Add transition for smooth effect */
}

.user-icon:hover {
  transform: scale(1.1); /* Expand the icon on hover */
  border-color: #1ab0a4; /* Change border color on hover */
  color: #1ab0a4; /* Change text color on hover */
}

@media screen and (max-width: 768px) {
  /* Adjustments for smaller screens */
  .signup-button{
    width: 60px;
    padding: 5px 5px;
    align-items: center;
    text-align: center;
    font-size: 9px;
  }
  .logo {
    width: 120px; /* Adjust logo size for smaller screens */
  }
  
  .user-icon {
    font-size: 14px; /* Decrease icon size for smaller screens */
    padding: 4px; /* Decrease padding for smaller screens */
    margin-right: 20px; /* Add margin to the right to prevent overlapping */
  }

  .header-container {
    max-width: 100%;
    height: 40px;
    padding: 0;
  }
}
@media only screen and (min-width: 820px) and (max-width: 1024px) {
  .logo-container img{
    height: 20px;
    width: 120px;
    object-fit: cover;
  }
  .signup-button{
    width: 60px;
    padding: 5px 5px;
    align-items: center;
    text-align: center;
    font-size: 9px;
  }
}
@media only screen and (min-width: 1186px) and (max-width: 1227px) {
  .signup-button{
    width: 60px;
    padding: 5px 5px;
    align-items: center;
    text-align: center;
    margin-right: -20px;
    font-size: 1.5vh;
    position: relative;
    right: 2rem;
  }
}
@media only screen and (min-width: 1228px) and (max-width: 1331px) {
  .signup-button{
    width: 60px;
    padding: 5px 5px;
    align-items: center;
    text-align: center;
    font-size: 1.5vh;
  }
}
@media only screen and (min-width: 1228px) and (max-width: 1331px) {
  .signup-button{
    width: 60px;
    padding: 5px 5px;
    align-items: center;
    text-align: center;
    font-size: 1.5vh;
    position: relative;
    right: -0.8rem;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1096px) {
  .signup-button{
    width: 60px;
    padding: 5px 5px;
    align-items: center;
    text-align: center;
    font-size: 1.5vh;
    position: relative;
    right: -0.8rem;
  }
}
@media only screen and (min-width: 769px) and (max-width: 945px) {
  .signup-button{
    width: 45px;
    padding: 5px 5px;
    align-items: center;
    text-align: center;
    font-size: 1.1vh;
    position: relative;
    right: -1rem;
  }
}
@media only screen and (min-width: 769px) and (max-width: 782px) {
  .signup-button{
    width: 45px;
    padding: 5px 5px;
    align-items: center;
    text-align: center;
    font-size: 1.2vh;
    position: relative;
    right: -0.8rem;
  }
}

@media only screen  and (max-width: 374px) {
  .signup-button{
    width: 60px;
    padding: 5px 5px;
    align-items: center;
    text-align: center;
    font-size: 9px;
  }
}