:root {
    --windowBackground: #f0f0f0; /* Background color for the main window */
    --headerBackground: #191970; /* Background color for the header */
    --textColor: blue; /* Default text color for the information */
    --labelColor: black; /* Color for the labels */
    --valueColor: #1E90FF; /* Color for the values */
    --dividerColor: black; /* Color for the divider line */
    --infoButtonSize: 40px; /* Size of the info button */
    --maxWidth: 50%; /* Maximum width for containers */
    --padding: 5px; /* General padding */
    --margin: 10px 0; /* General margin */
    --fontSize: 15px; /* General font size */
    --textAlign: center; /* Center alignment for text */
    --headingFontSize: 35px; /* Font size for the heading */
    --headingColor: #116C65; /* Color for the heading */
    --inStockColor: green; /* Color for In Stock status */
    --outOfStockColor: red; /* Color for Out of Stock status */
    --borderRadius: 5px; /* Border radius for rounded corners */
    --boxShadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
    --transitionDuration: 0.3s; /* Transition duration for smooth effects */
}
 
body {
    font-family: Arial, sans-serif; /* Default font family */
    line-height: 1.6; /* Improved readability */
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10%;
    background: var(--windowBackground);
    position: relative;
    align-items: center; /* Center contents horizontally */
    justify-content: center; /* Center contents vertically */
    padding: var(--padding);
    box-sizing: border-box;
}

.scrollable-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    min-width: 10%;
    max-width: 600px;
    overflow-y: auto;
    padding: var(--padding);
    box-sizing: border-box;
  }
  .stock-info-icon{
    height: 20px;
    width: 20px;
    cursor: pointer;
    float: right;
    position: relative;
    left: 35%;
  }
  .existingsite-data .heading {
    font-size: var(--headingFontSize);
    color: var(--headingColor);
    margin-bottom: 20px; /* Space between heading and content */
    text-align: var(--textAlign); /* Center the heading text */
    font-weight: bold; /* Bold text for emphasis */
    margin-top: -30px;
}

.linear-layout {
    width: 100%;
    max-width: 500px; /* Max width for the layout */
    height: auto; /* Let the height adjust based on content */
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: var(--margin); /* General margin */
    background: white;
    border-radius: var(--borderRadius); /* Rounded corners */
    box-shadow: var(--boxShadow); /* Apply box shadow */
    align-items: center; /* Center content horizontally */
    transition: all var(--transitionDuration) ease; /* Smooth transition effects */
}

.linear-layout:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
}

.horizontal-layout {
    display: flex;
    gap: 8px; /* Adjust as needed */
    text-align: center; /* Center text horizontally */
}

.label {
    font-family: 'Arial', sans-serif; /* Sans-serif font for labels */
    color: var(--labelColor);
    font-size: var(--fontSize);
    font-weight: bold;
}

.value {
    color: var(--valueColor);
    font-size: var(--fontSize);
}

.divider {
    width: 100%;
    height: 1px;
    margin: 8px 0;
    background: var(--dividerColor);
}

.info-button {
    width: var(--infoButtonSize);
    height: var(--infoButtonSize);
    position: absolute;
    bottom: 5%;
    right: 5%;
    cursor: pointer;
    background: var(--headingColor); /* Match the header color */
    border: none;
    border-radius: 50%; /* Round button */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease; /* Smooth background color transition */
}

.info-button:hover {
    background: darkblue; /* Darker color on hover */
}

.in-stock {
    color: var(--inStockColor); /* Text color for In Stock */
}

.out-of-stock {
    color: var(--outOfStockColor); /* Text color for Out of Stock */
}

@media (max-width: 768px) {
    .existingsite-data .heading{
        font-size: 1.5rem;
        margin-top: -30px;
    }
    .scrollable-container {
        max-width: 100%; /* Full width on smaller screens */
        padding: 10px; /* Adjust padding for smaller screens */
    }

    .linear-layout {
        max-width: 100%; /* Full width on smaller screens */
        padding: 15px; /* Adjust padding for smaller screens */
    }

    .info-button {
        width: 28px; /* Slightly smaller info button */
        height: 28px; /* Slightly smaller info button */
        bottom: 3%; /* Adjust position for smaller screens */
        right: 3%; /* Adjust position for smaller screens */
    }

}

@media (max-width: 480px) {
    .linear-layout {
        padding: 10px; /* Adjust padding further for very small screens */
        margin: 5px 0; /* Reduced margin for very small screens */
    }

    .horizontal-layout {
        flex-direction: column; /* Stack label and value vertically on very small screens */
        gap: 5px; /* Adjust gap for very small screens */
    }

    .label, .value {
        font-size: 14px; /* Slightly smaller font size for very small screens */
    }

    .info-button {
        width: 24px; /* Even smaller info button */
        height: 24px; /* Even smaller info button */
        bottom: 2%; /* Adjust position for very small screens */
        right: 2%; /* Adjust position for very small screens */
    }
    
}
@media only screen and (max-width:1028px) {
    .stock-info-icon{
        height: 20px;
        width: 20px;
        cursor: pointer;
        float: right;
        position: relative;
        left: 26%;
      }
}
@media only screen and (max-width:875px) {
    .stock-info-icon{
        height: 20px;
        width: 20px;
        cursor: pointer;
        float: right;
        position: relative;
        left: 13%;
      }
}
@media only screen and (max-width:525px) {
    .stock-info-icon{
        height: 20px;
        width: 20px;
        cursor: pointer;
        float: right;
        position: relative;
        left: 5%;
      }
}
@media only screen and (max-width:768px) and (min-width:700px) {
    .stock-info-icon{
        height: 20px;
        width: 20px;
        cursor: pointer;
        float: right;
        position: relative;
        left: 34%;
      }
}