/* AccountDetails.css */

.page-heading {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .account-options {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .account-options button {
    margin: 0 10px;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #116C65;
    color: white;
    width: 2in;
  }
  
  .account-options button:hover {
    background-color: #1ab0a4;
  }
  
  .account-details {
    text-align: center;
  }
  
  .account-info {
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .account-info-vertical {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .account-info-item {
    margin-bottom: 8px;
  }
  
  .account-info h2 {
    margin-bottom: 15px;
  }
  
  .account-info ul {
    list-style-type: none;
    padding: 0;
  }
  
  .account-info li {
    margin-bottom: 8px;
  }
  
  p {
    font-style: italic;
  }
  @media  only screen and (max-width:700px){
    .account-options {
      display: block;
      justify-content: center;
      margin-bottom: 20px;
      min-width: 100%;

    }
    .account-options button {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 8px 16px;
      font-size: 16px;
      cursor: pointer;
      border: none;
      border-radius: 4px;
      background-color: #116C65;
      color: white;
      width: 2in;
    }
    
  }