/* RawMaterialsPage.css */

.raw-materials {
    padding: 50px;
    background-color: #f0f0f0;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .raw-materials h1 {
    font-size: 2.7rem;
    color: black;
    font-weight: bold;
   
  }
  
  .navbar ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  .navbar li {
    margin: 0 10px;
  }

  .navbar li {
    background-color: #116C65;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s;
  }
  
  .navbar li:hover {
    background-color: #1ab0a4;
  }
  
  .content-box {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px;
    margin: 10px;
  }
  
.form-box {
  width: 100%; /* Set the width to 500px */
  height: fit-content; /* Set the height to 500px */
  background-color: lightcyan;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  overflow-y: auto; /* Enable vertical scrolling */
}
/* Styles for the download options */
.download-options {
  list-style: none;
  padding: 0;
  margin-top: -5px;
  position: absolute;
  display: none;
  flex-direction: column;
  width: max-content;
}

.download-options li {
  padding: 5px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.download-options li:hover {
  background-color: #1ab0a4;
}

/* Show download options when hovered */
.download-container:hover .download-options {
  display: block;
}


@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center; /* Align items to center */
  }

  .navbar li {
    margin: 5px 0; /* Adjust margin for smaller screens */
    font-size: 0.8rem; /* Decrease font size for smaller screens */
    padding: 5px; /* Adjust padding for the navbar items */
    margin-right: 3px;
  }

  .content-box {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }

  .order-status {
    width: 100%; /* Take full width on smaller screens */
  }

  .form-box {
    width: 100%; /* Take full width on smaller screens */
    height: auto; /* Auto height to accommodate content */
    max-width: none; /* Remove max-width for smaller screens */
    padding: 0%;
  }
}
  