.codeunblock-form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px 50px 40px 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .codeunblock-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
  }
  
  .form-group input[type="text"],
  .form-group input[type="file"] {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-button {
    display: block;
    width: 50%;
    margin: 0 auto; /* Centers the button horizontally */
    padding: 10px;
    background-color: #116C65;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  
  .submit-button:hover {
    background-color: #1ab0a4;
  }
  
  .form-group button {
    display: block;
    width: 50%;
    margin: 15px auto; /* Margin from top and bottom, and horizontally centered */
    padding: 10px;
    background-color: #116C65;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  @media only screen and (max-width:768px) {
    .codeunblock-form-container .submit-button{
      width: 85%;
    }
  }