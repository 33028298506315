/* ReturnPlcy.css */

html {
  margin: 0;
  overscroll-behavior: contain;
}
.services {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding-top:30px;
  margin-bottom: 30px;
}

.services h1 {
  color: #333;
  font-size: 3rem;
}
.services h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

/* styling cement ,steel, machinery, finance section  */
.services .heading, .heading2, .heading3, .heading4{
  color:black;
  font-size:2rem;
  font-weight:bold;
  white-space: nowrap;
}
.container-fluid .itemsImg, .itemsImg2, .itemsImg3, .itemsImg4{
  max-width: 30vw;
  flex: 0 0 50%;
  border-radius:5px;
  margin-left: 3rem;
  margin-right: 3rem;
  white-space: nowrap;
  margin-top: 55px;
}
.container-fluid .itemsImg:hover , .itemsImg2:hover, .itemsImg3:hover, .itemsImg4:hover{
  cursor:pointer;
  }
  .container-fluid .details,.details2,.details3,.details4 {
    font-size:1.3rem;
    margin-left:1rem;
    margin-right: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    color: #333;
  }
  .row{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  
  
  /* Adding media query  */
 
  @media only screen and (min-width: 200px) and (max-width: 768px) {
    .services .heading, .heading2, .heading3, .heading4{
      color:black;
      font-size:1.5rem;
      font-weight:bold;
      white-space: nowrap;
      margin-top: 3rem;
    }
  .row{
    display:block;
    justify-content: space-evenly;
    align-items: center;
  }
  .container-fluid .itemsImg, .itemsImg2, .itemsImg3, .itemsImg4{
    max-width: 100%;
    border-radius:5px;
    margin-left: 0rem;
    margin-right: 0rem;
    white-space: nowrap;
    margin-top: 55px;
  }
  .container-fluid .details,.details2,.details3,.details4 {
    font-size:1rem;
    margin-left:0rem;
    margin-right: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
    color: #333;
  }
  }
  @media only screen and (max-width: 312px) {
    .services .heading, .heading2, .heading3, .heading4{
      color:black;
      font-size:6vw;
      font-weight:bold;
      white-space: nowrap;
      margin-top: 3rem;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 1023px) {
    .services .heading, .heading2, .heading3, .heading4{
      color:black;
      font-size:1.3vh;
      font-weight:bold;
      white-space: nowrap;
      margin-top: 3rem;
    }
  .row{
    display:flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .container-fluid .itemsImg, .itemsImg2, .itemsImg3, .itemsImg4{
    max-width: 100%;
    border-radius:5px;
    margin-left: 0rem;
    margin-right: 0rem;
    white-space: nowrap;
    margin-top: 55px;
  }
  .container-fluid .details,.details2,.details3,.details4 {
    font-size:1.1vh;
    margin-left:0rem;
    margin-right: 0rem;
    padding-left: 2rem;
    padding-right: 2rem;
    color: #333;
  }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .services h1 {
      padding-top: 40px;
    }
    .services .heading, .heading2, .heading3, .heading4{
      color:black;
      font-size:3vh;
      font-weight:bold;
      white-space: nowrap;
      margin-top: 3rem;
    }
  .row{
    display:flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .container-fluid .itemsImg, .itemsImg2, .itemsImg3, .itemsImg4{
    max-width: 100%;
    border-radius:5px;
    margin-left: 0rem;
    margin-right: 0rem;
    white-space: nowrap;
    margin-top: 55px;
  }
  .container-fluid .details,.details2,.details3,.details4 {
    font-size:2vh;
    margin-left:0rem;
    margin-right: 0rem;
    padding-left: 2rem;
    padding-right: 2rem;
    color: #333;
  }
  }
