.contact-bg {
    position: relative; /* Ensure relative positioning for absolute positioning of child elements */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 100%;
    height: 80vh; /* Take up full viewport height */
    background-color: #0056b3;
    background-image: url('/public/Images/image.png'); /* Path to your image */
    background-size: cover; /* Set the background size to contain */
    background-repeat: no-repeat; 
    background-position: center;
}

.contact-container {
    padding: 50px;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center; 
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.666); /* Transparent background */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.company-address {
    margin-bottom: 20px;
    text-align: center; /* Center-align content within .company-info */
}

.company-address h1 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
}

.company-address p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
    color: #333;
    font-weight: bold;
}

.contact h2,
.quick-contact h2,
#click-to-call h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
}

.contact p {
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center; /* Center-align content within .contact */
    color: #333;
    font-weight: bold;
}

.contact a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
}

.contact a:hover {
    text-decoration: underline;
}

.call-now-button {
    background-color: #0077b6;
    color: white;
    padding: 11px 20px;
    border: none;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.6vh;
    line-height: 1;
    text-decoration: none;
    transition: background-color 0.3s;
    display: inline-block;
    font-weight: bold; 
    text-transform: uppercase; 
}

.quick-contact p {
    font-size: 16px;
    margin-bottom: 20px;
}
.enq-whatsapp{
    background-color: aliceblue;
    color: #333;
    padding-left: 10px;
    height: 40px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s;
    display: inline-block;
    font-weight: bold; 
    text-transform: uppercase; 
    margin-top: 10px;
}
.enq-whatsapp img{
height: 24px;
object-fit: cover;
margin-right: 8px;
margin-top: 6px;
}
.btnFlex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.enq-whatsapp .div-whatsp {
    display: flex;             
    align-items: center;   
  }
  
  .enq-whatsapp .div-whatsp span {
    margin-right: -2px;  
    font-size: 1.6vh;   
    align-items: center;  
    margin-top: 10px;
  }

  @media only screen and (max-width:600px) {
    .call-now-button {
        height: 17px;
    }
  }
  @media only screen and (width:344px) {
    .call-now-button {
        height: 10px;
        padding-bottom: 20px;
        font-size: 13px;
        white-space: nowrap;
    }
    
  }
  @media only screen and (min-width:800px) and (max-width:1300px){
    .call-now-button {
        height: 17px;
        line-height: 1;
    }
    .enq-whatsapp .div-whatsp span {
        line-height: 1;
      }
  }
  