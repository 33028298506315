body, html {
  overflow-x: hidden;
}

.About-container {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: beige;
  
}

.About-container h1 {
  color: #333;
  font-size: 2rem;
 
}

.About-container h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}
.About-container p {
  text-align: center;
  margin-bottom: 15px;
  color: #333;
  font-style: normal;
  font-size:20px;

}
.first-row {
  position: relative;
  height: 500px;
}

.first-row .background-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.first-row .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.391); 
  display: flex;
  justify-content: center;
  align-items: center;
}


.first-row .overlay-content h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
}

.first-row .overlay-content p {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: aqua;
}

/* Styling for the second-row container */
.second-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0;
  background-color: #f2f2f2; /* Background color */
}

/* Styling for the content container in the second-row */
.second-row .content {
  flex: 0 0 50%;
  padding: 0 20px; /* Adjust padding as needed */
}

/* Styling for the paragraph and heading elements in the second-row content */
.second-row .content h2,
.second-row .content p {
  text-align: center;
  margin-bottom: 20px;
}

.second-row .content h2 {
  font-size: 2.5rem; /* Adjust heading font size */
  color: #333; /* Heading color */
}

.second-row .content p {
  font-size: 1.5rem; /* Adjust paragraph font size */
  color: #666; /* Paragraph color */
}

/* Styling for the image container in the second-row */
.second-row .image {
  flex: 0 0 50%;
}

.second-row .image img {
  width: 500px;
  height: 500px; /* Fill the container height */
  object-fit: cover; /* Maintain aspect ratio */
}

/* Styling for the third-row container */
.third-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0;
}


/* Styling for the paragraph and heading elements in the third-row content */
.third-row .content h2,
.third-row .content p {
  text-align: center;
  margin-bottom: 20px;
}

.third-row .content h2 {
  font-size: 2rem; /* Adjust heading font size */
  color: #333; /* Heading color */
}

.third-row .content p {
  font-size: 1.5rem; /* Adjust paragraph font size */
  color: #666; /* Paragraph color */
  margin-top: 20px;
}


.third-row .image img {
  width: 500px;
  height: 500px; /* Fill the container height */
  object-fit: cover; /* Maintain aspect ratio */
  margin-left: 200px;
}



.fifth-row {
  position: relative;
  height: max-content;
}

.fifth-row .background-image {
  width: 100%;
  height: 100%;
}

.fifth-row .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.343);
  display: flex;
  justify-content: center;
  align-items: center;
}

.fifth-row .overlay-content {
  text-align: center;
  color: #fff;
}

.fifth-row .overlay-content h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
}

.fifth-row .overlay-content p {
  font-size: 1rem;
  margin-bottom: 10px;
}


.fifth-row .overlay-content p {
  font-size: 1.2rem;
  color: #fff;
}

.fifth-row .overlay-content h2 {
  font-size: 2rem;
  color: #fff;
}

/* Media query for screens with a maximum width of 768px (mobile view) */
@media only screen and (max-width: 767px) {
  .first-row .overlay {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 99%;
    background-color: rgba(0, 0, 0, 0.391); 
    display: flex;
    justify-content: center;
    align-items: center;
  }
.first-row .overlay-content p{
  padding-left: 1rem ;
  padding-right: 1rem;
}
.second-row .content p{
  padding-left: 1rem;
  padding-right: 3rem;
}
.third-row .content p{
  padding-left: 1rem;
  padding-right: 1rem;
}
.third-row .content h1{
  font-size: 1.8rem;
}
.About-container .section p{
 padding-right: 3rem;
}
.About-container {
  max-width: 100%;
  margin-left: 0rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: beige;
  
}
.fifth-row .overlay {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.343);
  display: flex;
  justify-content: center;
  align-items: center;
}

}
@media screen and (width: 375px) {
  .first-row .overlay {
    position: absolute;
    top: -22px;
    left: 0;
    width: 100%;
    height: 99%;
    background-color: rgba(0, 0, 0, 0.391); 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fifth-row .overlay {
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 99%;
    background-color: rgba(0, 0, 0, 0.343);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fifth-row .overlay {
    position: absolute;
    top: -25px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.343);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (width: 390px) {
  .first-row .overlay {
    position: absolute;
    top: -37px;
    left: 0;
    width: 100%;
    height: 99%;
    background-color: rgba(0, 0, 0, 0.391); 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fifth-row .overlay {
    position: absolute;
    top: -40px;
    left: 0;
    width: 100%;
    height: 99%;
    background-color: rgba(0, 0, 0, 0.343);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (width: 430px) {
  .first-row .overlay {
    position: absolute;
    top: -37px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.391); 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fifth-row .overlay {
    position: absolute;
    top: -38px;
    left: 0;
    width: 100%;
    height: 99%;
    background-color: rgba(0, 0, 0, 0.343);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (width: 412px) {
  .first-row .overlay {
    position: absolute;
    top: -30px;
    left: 0;
    width: 100%;
    height: 99%;
    background-color: rgba(0, 0, 0, 0.391); 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fifth-row .overlay {
    position: absolute;
    top: -30px;
    left: 0;
    width: 100%;
    height: 99%;
    background-color: rgba(0, 0, 0, 0.343);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (width: 360px) {
  .first-row .overlay {
    position: absolute;
    top: -9px;
    left: 0;
    width: 100%;
    height: 99%;
    background-color: rgba(0, 0, 0, 0.391); 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fifth-row .overlay {
    position: absolute;
    top: -9px;
    left: 0;
    width: 100%;
    height: 99%;
    background-color: rgba(0, 0, 0, 0.343);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (width: 540px) {
  .first-row .overlay {
    position: absolute;
    top: -28px;
    left: 0;
    width: 100%;
    height: 99%;
    background-color: rgba(0, 0, 0, 0.391); 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fifth-row .overlay {
    position: absolute;
    top: -30px;
    left: 0;
    width: 100%;
    height: 99%;
    background-color: rgba(0, 0, 0, 0.343);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  /* Adjustments for mobile view */
  
  /* Reduce font sizes for better readability on mobile */
  .About-container h1,
  .first-row .overlay-content h1,
  .second-row .content h2,
  .third-row .content h2,
  .fifth-row .overlay-content h1 {
    font-size: 1.5rem;
  }

  .About-container h2,
  .About-container p,
  .first-row .overlay-content p,
  .second-row .content p,
  .third-row .content p,
  .fifth-row .overlay-content p {
    font-size: 1rem;
    margin-bottom: 10px;
  }

 
  /* Set container width to 100% for better responsiveness */
  .About-container,
  .first-row,
  .second-row,
  .third-row,
  .fifth-row {
    width: 100%;
    height: 100%;
  }

  /* Additional adjustments for other elements as needed */
  
  /* Override any conflicting styles for the second row */
  .second-row {
    padding: 20px 0;
  }

  .second-row .content,
  .second-row .image {
    flex: 0 0 100% ;
  }

  .second-row .content h2 {
    font-size: 1.5rem;
  }

  .second-row .image img {
    width: 100%;
    height: auto;
  }

  /* Override any conflicting styles for the third row */
  .third-row {
    flex-direction: column;
    align-items: center;
  }

  .third-row .content {
    flex: 0 0 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }

  .third-row .image img {
    width: 100%;
    height: auto;
    margin-left: 0;
  }
  .About-container .section p{
    padding-right: 3rem;
    
   }
}
@media only screen and (width: 768px) {
  /* Adjustments for mobile view */
  
  /* Reduce font sizes for better readability on mobile */
  .About-container h1,
  .first-row .overlay-content h1,
  .second-row .content h2,
  .third-row .content h2,
  .fifth-row .overlay-content h1 {
    font-size: 1.5rem;
  }

  .About-container h2,
  .About-container p,
  .first-row .overlay-content p,
  .second-row .content p,
  .third-row .content p,
  .fifth-row .overlay-content p {
    font-size: 1rem;
    margin-bottom: 10px;
  }

 
  /* Set container width to 100% for better responsiveness */
  .About-container,
  .first-row,
  .second-row,
  .third-row,
  .fifth-row {
    width: 100%;
    height: 100%;
  }

  /* Additional adjustments for other elements as needed */
  
  /* Override any conflicting styles for the second row */
  .second-row {
    padding: 20px 0;
  }

  .second-row .content,
  .second-row .image {
    flex:   100% ;
  }

  .second-row .content h2 {
    font-size: 1.5rem;
  }

  .second-row .image img {
    width: 100%;
    height: auto;
  }

  /* Override any conflicting styles for the third row */
  .third-row {
    flex-direction: column;
    align-items: center;
  }

  .third-row .content {
    flex: 0 0 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }

  .third-row .image img {
    width: 100%;
    height: auto;
    margin-left: 0;
  }
  .About-container .section p{
    padding-right: 3rem;
    
   }
   .About-container {
    max-width: 100%;
    margin-left: 0rem;    
  }
  .first-row .overlay {
    position: absolute;
    top: -15px;
    left: 0;
    width: 100%;
    height: 99%;
    background-color: rgba(0, 0, 0, 0.391); 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fifth-row .overlay {
    position: absolute;
    top: -17px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.343);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 769px) and (max-width: 819px) {
  .first-row .overlay {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.391); 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fifth-row .overlay {
    position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.343);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .second-row .image img {
    width: 100%;
    min-height: 250%;
    margin-right: 1rem;
  }
  .third-row .content {
    flex: 100% 0 0;
    margin-left:8px;
    margin-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
  }

  .third-row .image img {
    width: 100%;
    height: auto;
    margin-left: 0;
  }
}

@media only screen and (min-width: 820px) and (max-width: 1023px) {
  .second-row .image img {
    width: 100%;
    min-height: 250%;
    margin-right: 1rem;
  }
  .third-row .content {
    flex: 100% 0 0;
    margin-left:8px;
    margin-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
  }

  .third-row .image img {
    width: 100%;
    height: auto;
    margin-left: 0;
  }
}
@media only screen and (width:768px) {
  .first-row .overlay {
    position: absolute;
    top: -28px;
    left: 0;
    width: 100%;
    height: 99%;
    background-color: rgba(0, 0, 0, 0.391); 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fifth-row .overlay {
    position: absolute;
    top: -28px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.343);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

