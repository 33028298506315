/* General styles */
.orderstatus-page {
  border: 1px solid #1ab0a4;
  padding: 50px;
}

.page-heading {
  font-size: 3rem;
  color: #116C65;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  margin: 0;
  text-align: center;
  font-weight: bold;
}

/* Scrollable container */
.order-containers {
  display: flex;
  flex-direction: column; /* Ensure vertical layout in mobile */
}

.right-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Take complete width in mobile */
  overflow-y: auto;
  margin: 8px;
  box-sizing: border-box;
}

.left-card {
  width: 100%;
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc;
  margin: 8px;
  background-color: #ffebcd;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  height: 70vh; /* Fixed height for testing */
}

.order-container {
  width: 100%;
  margin: 0 0 10px 0;
}
.order-container .balance-data{
  font-size: 1.5rem;
  background-color: #116C65;
  color: #ffffff;
  padding: 10px;
  margin: 0;
  border-radius: 5px 5px 0 0;
  text-align: center;
}
.order-container .balance-data img{
  float: right;
}
.order-container p {
  margin-bottom: 20px;
}

/* Order details */
.order-details {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  margin: 8px;
}

/* Specific classes */
.dispatchData {
  color: #116C65;
}

/* Style for the heading bar */
.order-container h2 {
  font-size: 1.5rem;
  background-color: #116C65;
  color: #ffffff;
  padding: 10px;
  margin: 0;
  border-radius: 5px 5px 0 0;
  text-align: center;
}

/* Style for the content frame */
.order-container .balance-card,
.order-container .dispatch-card,
.order-container .pending-card {
  border: 1px solid #3498db;
  border-radius: 0 0 5px 5px;
  padding: 10px;
}

/* Style for the "Show More" button */
.order-container button {
  background-color: #2ecc71;
  color: #ffffff;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  margin-top: auto; /* Push the button to the bottom of the container */
  cursor: pointer;
  display: block; /* Ensure the button takes full width */
  margin: auto;
}

/* Style for the button on hover */
.order-container button:hover {
  background-color: #27ae60;
}
/* Style the information circle  */


.info-icon1,.info-icon2,.info-icon3, .info-img {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.info-circle1 .info-icon1{
  position: relative;
  top: -2.1rem;
  left: 51rem;
}
.info-circle2 .info-icon2{
  position: relative;
  top: -2.1rem;
  left: 51rem;
}
.info-circle3 .info-icon3{
  position: relative;
  top: -2.1rem;
  left: 51rem;
}
.order-container.pending {
  overflow: hidden; /* add this to prevent content from overflowing */
}

.pending-card {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  word-break: break-word; /* add this to prevent long words from breaking out of the container */
  overflow-wrap: break-word; /* add this to prevent long words from breaking out of the container */
}

.pending-card p {
  margin-bottom: 10px;
}

.pending-card button {
  margin-top: 10px;
}
@media screen and (min-width: 768px) {
  .order-containers {
    flex-direction: row; /* Horizontal layout for larger screens */
    margin: 10px;
  }

  .right-container,
  .left-container {
    max-width: calc(50% - 20px); /* Adjust for margin */
    max-height: calc(60vh - 40px); /* Adjust for header and padding */
  }
  .pending-card {
    font-size: 14px; /* adjust font size to fit smaller screen */
    padding: 5px; /* adjust padding to fit smaller screen */
  }
  .pending-card p {
    margin-bottom: 5px; /* adjust margin to fit smaller screen */
  }
  .pending-card button {
    margin-top: 5px; /* adjust margin to fit smaller screen */
  }
}
