/* ChatBot.css */
.chatbot-container {
    position: fixed;
    bottom: 0;
    right: 20px;
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .chatbot-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #f5f5f5;
    border-bottom: 1px solid #ccc;
  }
  
  .chatbot-messages {
    max-height: 400px;
    overflow-y: auto;
    padding: 10px;
  }
  
  .chatbot-message {
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
  }
  
  .chatbot-message.user {
    background: #d1e7dd;
    align-self: flex-end;
  }
  
  .chatbot-message.bot {
    background: #f1f1f1;
  }
  
  .chatbot-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
  }
  
  .chatbot-input input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .chatbot-input button {
    padding: 10px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
  }
  