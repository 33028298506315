/* Add this CSS to your project's stylesheets or use a CSS-in-JS solution */

/* Style for the footer container */
footer {
  background: #116C65;
  color: #fff; /* Text color */
  padding: 20px;
  text-align: center;
}
.app-link {
  display: flex;
  justify-content: end;
  align-items: center;
}
.app-link a{
 margin: 5px;
 margin-top: -1.5rem;
}
.app-icon{
  width: 120px;
  height: 40px;
}
.apple-icon{
  width: 130px;
}
/* Style for the "Follow Us" heading */
h4 {
  font-size: 2rem;
  margin: 10px;
}
.dropdown-content p {
  color: #333;
}

/* Style for social media icons */
a.text-3xl {
  font-size: 3rem; /* Adjust the size as needed */
  text-decoration: none;
  transition: color 0.3s;
  color: #fff;
  margin-right: 30px;
}

.fb:hover {
  color: #007bff; /* Change Facebook icon color on hover */
}

.insta:hover {
  color: orangered; /* Change Facebook icon color on hover */
}

.tweet:hover {
  color: #333; /* Change Facebook icon color on hover */
}

.youtube:hover {
  color: red; /* Change Facebook icon color on hover */
}

.linked:hover {
  color: #007bff; /* Change Facebook icon color on hover */
}

.footer-nav-bar {
  display: flex; /* Use flexbox for easy centering */
  justify-content: center; /* Center items horizontally */
  /* Other styles for .mech-nav-bar */
  margin-top: -60px;
  margin-right: 120px;
}

/* Style for navigation items */
.ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Style for list items in the footer */
 ul li {
  display: inline-block; /* Display them as inline-block elements */
  margin-right: 30px;
}


/* Style for navigation item links */
ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
}

ul li a:hover {
  color: #1ab0a4; /* Color when hovering (adjust as needed) */
}

/* Style for the current year and company name */
.text-center {
  text-align: center;
}
footer .whatsapp{
  height: 38px;
  width: 130px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  float:right;
  position: relative;
  bottom: 22px;
  border-radius: 6px;
  cursor: pointer;
}
footer .whatsapp .whatsapp-icon{
width: 30px;
text-align: center;
margin-top: 3px;
margin-left: -10px;
width: 40px;
height: 30px;
object-fit: cover;
}
footer .whatsapp p{
  font-size: 8px;
  color: #fff;
  margin-left: 5px;
  margin-bottom: 3px;
  margin-left: -6px;
}
footer p.number{
  font-size: 13px;
  margin-left: -6px;
} 
.images-container{
  margin-right: 30px;
 }

/* For screens smaller than 768px */
@media screen and (max-width: 768px) {
  h4,
  a.text-3xl,
  ul.ul li {
    width: 100%; /* Take up full width */
    text-align: center; /* Center the text */
    margin: 0 10px; /* Adjusted margin for better spacing */
  }

  a.text-3xl {
    font-size: 3rem; /* Increase font size */
  }

  ul.ul li a {
    font-size: 1.5rem; /* Increase font size */
  }

  .text-center p {
    font-size: 1rem; /* Adjust font size */
  }
  .footer-app{
    display: block;
  }
  .images-container{
    display: block;
    justify-content: center;
    align-items: center;
  }
  footer .whatsapp{
    height: 45px;
    width: 120px;
    float: left;
    left: 10px;
  }
  footer .whatsapp p{
    font-size: 7px;
    color: #fff;
    margin-left: 8px;
  }
  footer .whatsapp .whatsapp-icon{
    width: 30px;
    text-align: center;
    margin-top: 3px;
    margin-left: -1px;
    object-fit: cover;
    }
  footer p.number{
    font-size: 9px;
   }
  .footer-nav-bar {
    display: flex; /* Use flexbox for easy centering */
    justify-content: center; /* Center items horizontally */
    margin-top: -60px;
    margin-right: -30px;
  }
}
@media screen and (max-width: 500px) and (min-width:200px) {
  .footer-nav-bar {
    margin-top: -60px;
    margin-right: -50px;
  }
  .images-container{
   margin-right: 85px;
  }
}
@media screen and (max-width: 768px) and (min-width:500px) {
  .footer-nav-bar {
    margin-top: -60px;
    margin-right: -50px;
  }
  .images-container{
    margin-right: 160px;
   }
}
@media screen and (max-width: 1024px) and (min-width:769px) {
  .footer-nav-bar {
    margin-top: -60px;
    margin-right: -58px;
  }
  footer .whatsapp p{
    font-size: 8px;
    color: #fff;
    margin-left: -6px;
  }
  footer p.number{
    font-size: 13px;
    margin-left: -6px;
  } 
}
