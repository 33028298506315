/* Base styles */
.notifications-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto; /* Set your desired container width */
  height: 50px; /* Set your desired container height */
  overflow: hidden; /* Hide any overflowing content */
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
  
}

.notification-text {
  font-size: 20px;
  text-align: center;
  color: blue;
  white-space: nowrap;
  animation: scrollText 25s linear infinite;
}

@keyframes scrollText {
  0% {
    transform: translateX(90%);
  }
  100% {
    transform: translateX(-90%);
  }
}

@media (max-width: 768px) {
  .notification-text {
    font-size: 18px;
    padding: 10px;
    border-radius: 6px;
    animation: scrollTextMobile 25s linear infinite;
  }
}

@keyframes scrollTextMobile {
  0% {
    transform: translateX(60%);
  }
  100% {
    transform: translateX(-60%);
  }
}