/* machinery.css */
.machinery-page {
    font-family: Arial, sans-serif;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .machinery-block {
    background: linear-gradient(to bottom, #D2B48C, #8B4513);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: auto;
    width: 100%; /* Take full width of the viewport */
    height: auto;
    margin: 0 auto;
    border-radius: 15px; /* Curved border radius */
    padding: 20px;
  }
  
  .header-block h1 {
    font-size: 3rem;
    margin: 30px;
    color: #333;
  }
  
  .mech-nav-bar {
    display: flex; /* Use flexbox for easy centering */
    justify-content: center; /* Center items horizontally */
  }
  
  
  .mech-nav-bar ul {
    list-style: none;
    align-items: center;
  }
  
  .mech-nav-bar li {
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    padding: 5px;
    border: 1px solid #333;
    border-radius: 4px;
    width: 120px;
    text-align: center;
    transition: color 0.3s;
    background-color: #FAFAD2;
    color: #333;
    margin-left: 30px;
    margin-right: 30px;
  }
  
  .mech-nav-bar li:hover {
 background-color: #EEDD82; /* Change text color on hover */
  }
  
  .machinery-page h3 {
    text-align: center;
    font-size: 2rem;
    line-height: 1.5;
    margin-top: 50px;
    color: #333;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  
  .form-block {
    flex: 1;
    margin-left: 50px;
    margin-right: 40px;
    height: 650px; /* Fixed height for the form container */
    overflow-y: auto; /* Enable vertical scrolling inside the form container */
    background-color: #fff; /* Form container background color */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
   
    width: 100%;
  }

  .status-card {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .status-card h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .status-data {
    overflow: auto;
    max-height: 300px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  
  @media screen and (max-width: 768px) {
    .machinery-block {
      flex-direction: column; /* Stack elements vertically on smaller screens */
      align-items: center; /* Center-align items in the column */
     
    }
  
    .machinery-page .header-block h1 {
      font-size: 2.5rem; 
      margin: 20px; 
      text-align: center;
      align-items: center;
      justify-content: center;
      margin-left: -5px;
    }
  
    .machinery-page .mech-nav-bar ul {
      margin: 20px; 
      align-items: center;
      justify-content: center;
      margin-left: 20px;
    }
  
    .machinery-page .mech-nav-bar li {
      font-size: 1.2rem; 
      margin-bottom: 8px; 
      align-items: center;
      justify-content: center;
    }
  
    .machinery-page .header-block h3 {
      font-size: 1.8rem; 
      margin-top: 20px; 
      align-items: center;
      justify-content: center;
      margin-left: -10px;
      position: 5px;
      padding-right: 5px;
    }
    .status-card {
      margin-right: 2rem;
      margin-left: 0rem;
    }
  
    .form-block {
      margin: 20px; 
      height: auto; 
      max-height: 400px; 
      
    }
  }
  @media only screen and (width: 768px) and (height:1024){
    
    
  }

  @media only screen and (min-width: 769px) and (max-width:1170px) {
    .machinery-block {
      flex-direction: column; 
      align-items: center; 
    }
  
    .header-block h1 {
      font-size: 2.5rem; 
      margin: 20px; 
  
    .nav-bar ul {
      margin: 20px; 
    }
  
    .nav-bar li {
      font-size: 1.2rem;
      margin-bottom: 8px; 
    }
  
    .macinery h3 {
      font-size: 1.8rem; 
      margin-top: 20px; 
    }
  
    .form-block {
      margin: 20px; 
      height: auto; 
      max-height: 400px;
    }
  }
}
  @media only screen and (min-width: 400px) and (max-width:473px) {
    .machinery-page .mech-nav-bar ul {
      margin: 20px; 
      align-items: center;
      justify-content: center;
      margin-left: 50px;
    }
  }
  @media only screen and (max-width:353px) {
    .machinery-page .mech-nav-bar ul {
      margin: 20px; 
      align-items: center;
      justify-content: center;
      margin-left: 10px;
    }
  }