.cement-form {
  max-width: 100wh;
  padding: 20px;
  border: 1px solid #1ab0a4;
  border-radius: 10px;
  margin: 0 auto;
  padding-top: 70px;
}

.cement-form .heading {
  font-size: 30px;
  margin-bottom: 30px;
  margin-top: -30px;
}

.form-group {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  width: 90%; /* Set width to 90% */
  text-align: center;
  margin: 0 auto; /* Center the form-group */
  overflow: auto; /* Enable auto-flow for content */
  max-height: 50vh; /* Set maximum height to enable vertical scrolling */
}
.form-group .balance-data img{
float: right;
}

.form-group select,
.form-group input[type="text"],
.form-group input[type="number"] {
  padding: 10px;
  border: 1px solid #1ab0a4;
  border-radius: 5px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin: 10px 0;
}

.cement-form .submit-button {
  background-color: #116C65;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  font-weight: bold; /* Add bold font weight */
  text-transform: uppercase; /* Convert text to uppercase */
  letter-spacing: 1px;
}

.cement-form .submit-button:hover {
  background-color: #1ab0a4;
}

p {
  font-size: 16px;
  color: #4caf50; /* Green color for success message */
  margin-top: 10px;
}

/* Styles for the brand and grade containers */
.cement-brands-container,
.cement-grades-container {
  display: flex;
  flex-wrap: wrap; /* Allow multiple rows if needed */
  justify-content: space-around; /* Adjust alignment as needed */
  gap: 10px; /* Adjust the gap between containers */
}

.cement-brand-container,
.cement-grade-container {
  width: 100px; /* Adjust the width of the container as needed */
  height: auto; /* Adjust the height of the container as needed */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.cement-brand-container img,
.cement-grade-container img {
  width: 120%;
  height: 120%;
  object-fit: cover; /* Maintain aspect ratio */
}

.cement-grade-container.selected, .cement-brand-container.selected {
  border: 3px solid #1ab0a4; /* Add a blue border to the selected image container */
  border-radius: 8px;
}

/* Updated styles for the quantity buttons */
.quantity-matrix {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 columns */
  grid-gap: 5px; /* Adjust the gap between buttons */
}

.custom-quantity-container{
  width: 150px;
  margin: 10px;
}

.quantity-matrix .quantity-button {
  width: 100%; /* Adjust button width as needed */
  height: 40px; /* Adjust button height as needed */
  border: 2px solid; /* Black border */
  border-radius: 20px; /* Adjust border-radius to get curved buttons */
  background-color: transparent;
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.quantity-button.selected {
  background-color: #116C65; /* Change background color for selected button */
  color: #fff; /* Change text color for better visibility */
}


.quantity-button {
  /* Other styles */
  text-align: center; /* Center align the text horizontally */
  display: flex; /* Use flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.site-address-options {
  margin-top: 5px;
}

.site-address-label {
  display: block;
  border: 1px solid #ccc; /* Add border to each address */
  border-radius: 5px; /* Add border-radius for rounded corners */
  padding: 5px 10px; /* Add padding for better spacing */
}

.site-address-label:hover {
  background-color: #f0f0f0; /* Add background color on hover */
}

.site-address-label input[type="radio"] {
  margin-right: 10px; /* Add margin between radio button and text */
}

.address-text {
  vertical-align: middle; /* Align text vertically */
}

.site-address-label input[type="radio"]:checked + .address-text {
  color: #116C65; /* Change text color to blue when selected */
  font-weight: bold; /* Add bold font weight when selected */
}

/* adding media query  */
@media  only screen and (max-width:768px){
  .form-group {
    margin-left: -0.5rem;
  }
  .cement-form{
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media  only screen and (max-width:1024px){
  .cement-form .form-group-container{
    display: block;
  }
  .form-group {
    margin-left: -0.5rem;
  }
  .cement-form{
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
