body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
}
.api-container{
    min-height: 500px;
    min-width: 100%;
    background-color: #fff;
    padding: 40px;
    padding: 50px;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center; 
    border-radius: 8px;
}
.api-container .api-address{
    margin-top: 50px;
    font-size: 6vh;
    font-weight: 700;
    text-align: center;
    color: #282828;
    box-shadow: rgba(0, 0, 0, 0.9);
    
}

.api-container .api-address img{
    height: auto;
    width: 90px;
    object-fit: cover;
    margin-top: 18px;
    float: right;
}
.api-container p{
    font-size: 3vh;
    color: #282828;
    margin-top: 1rem;
}

/* popup css  */
.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 200%;
    background-color: rgba(255, 254, 254, 0.874);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  .subscribe{
    margin-top: 2rem;
  }
  .subscribe:hover{
    background-color: #1ab0a4;
    
  }
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    width: 500px;
    max-width: 90%;
    margin-bottom: 40rem;
    
  }
  
  .close {
    font-size: 40px;
    cursor: pointer;
    color: #000;
  }
  
  .popup-header {
    text-align: center;
    margin-bottom: 20px;
  }
  .popup-header h2{
    font-size: 2rem;
  }
  .popup-body p{
    padding-left: 0;
    padding-right: 0;
    font-size: 1.3rem;
    font-weight: 300;
  }
  
  .popup-footer {
    display: flex;
    justify-content: space-around;
  }
  
  .popup-footer button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #116C65;
    color: white;
    cursor: pointer;
    margin-top: 50px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .popup-footer .stay{
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #f6a0a098;
    color: #000;
    cursor: pointer;
    margin-top: 50px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .QR-image{
    background-color: #fff;
  }
  .QR-image img{
    width: 80%;
    margin-left: 7rem;
  }

  @media only screen and  (max-width:768px){
    .api-container .api-address
  {
        margin-top: 50px;
        font-size: 5vw;
        font-weight: 700;
        text-align: center;
        color: #282828;
        box-shadow: rgba(0, 0, 0, 0.9);
        margin-left: -90px;
    }
    
    .api-container .api-address
   img{
        height: 8vw;
        width: 8vw;
        object-fit: cover;
        margin-top: 6rem; 
        float: right;
        position: relative;
        top:-6rem;
    }
    .api-container p{
        font-size: 4vw;
        color: #282828;
        margin-right: 5rem;
        margin-top: -5rem;
    }
    .subscribe{
       margin-left: -5vh;
       margin-right: 3vh;
      }
      .popup-content {
        width: 500px;
        max-width: 90%;
        height: 380px;
       margin-bottom: 40rem;
      }
      .QR-image img{
        width: 100%;
        margin-right: -2rem;
        margin-left: -3.8rem;
        margin-top: 1rem;
      }
      .popup-body p{
        padding-left: 0;
        padding-right: 0;
        font-size: 1.1rem;
        font-weight: 300;
        margin-top: 1rem;
        margin-left: 2rem;
        margin-right: 3rem;
      }

  }

  @media only screen and  (max-width:1365px) and (min-width:769px){
    .api-container .api-address
  {
        margin-top: 50px;
        font-size: 5vw;
        font-weight: 700;
        text-align: center;
        color: #282828;
        box-shadow: rgba(0, 0, 0, 0.9);
        margin-left: -90px;
    }
    
    .api-container .api-address
   img{
        height: 7vw;
        width: 7vw;
        object-fit: cover;
        margin-top: 6rem; 
        float: right;
        position: relative;
        top:-5.7rem;
    }
    .api-container p{
        font-size: 3vw;
        color: #282828;
        margin-right: 4rem;
        margin-top: -5rem;
    }
    .subscribe{
       margin-left: -5vh;
       margin-right: 3vh;
       padding-left: 5rem;
       padding-right: 5rem;
      }
      .QR-image img{
        width: 100%;
        margin-right: -2rem;
        margin-left: -3.8rem;
       margin-top: 1rem;
      }
      .popup-body p{
        padding-left: 0;
        padding-right: 0;
        font-size: 1.1rem;
        font-weight: 300;
        margin-top: 1rem;
        margin-left: 2rem;
        margin-right: 3rem;
      }
      .popup-content {
        width: 500px;
        max-width: 90%;
        height: 380px;
        margin-bottom: 35rem;
      }
  }

  @media only screen and  (max-width:1000px) and (min-width:800px){
    .popup-content {
      margin-bottom: 55rem;
      }
  }
  @media only screen and  (max-width:1100px) and (min-width:1000px){
    .popup-content {
      margin-bottom: 30rem;
      }
  }
