body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
.whatsapp-button{
    width: 80px;
    height: 50px;
    cursor: pointer;
    object-fit: cover;
  }
  .whatsapp-button-wrapper{
    position: fixed;
    bottom: 30px;
    right:10px;
    z-index: 9999;
  }
 