.container {
  background-image: url('/public/Images/ratebg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 50px 200px;
  height: 100%; /* Set a fixed height */
  overflow: hidden; /* Hide overflow content */
  display: flex; /* Use flexbox */
  justify-content: center; /* Align items horizontally to the center */
  align-items: center; /* Align items vertically to the center */
  flex-direction: column;
}


.container .existingsite-data  {
  text-align: center;
  margin-bottom: 50px;
  color: #116C65;
  font-size: 2.5vw;
  margin-top: 5rem;
  font-weight: 600;
}
.existing-info-icon{
  height: 20px;
  width: 20px;
  cursor: pointer;
  position: relative;
  left: 13vw;
  top: 3.7rem;
}

.newrate-container {
  width: 50%;
  height: 100%;
  overflow-y: auto;
  display: flex; /* Use flexbox to stack items */
}

.rate-frame {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1; /* Take up remaining vertical space */
    width: 50%;
  float: left;
  height: 100%;
  overflow-y: auto;
  display: flex; /* Use flexbox to stack items */
  flex-direction: column; /* Stack items vertically */
}

.rate-frame p{
  color: #0074cc;
  margin: 20px;
  font-weight: bold;
}
.container .address-frame .address p{
  color: blue;
}

.newrate-container  h3 {
  text-align: center;
  margin-bottom: 10px;
}

.newrate-container input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.newrate-container button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #0074cc;
  color: #fff;
  cursor: pointer;
}

.newrate-container button:hover {
  background-color: #0056b3;
}

.addresses-container {
  width: 50%; /* Adjust width as needed */
  float: right; /* Align to the right */
  height: 60vh; /* Set container height to 50% of viewport height */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds container height */
}

.addresses-container::-webkit-scrollbar {
  width: 0; /* Hide the scrollbar */
}

.address-frame {
  background-color: white; /* Set background color to transparent */
  border: 1px solid #ccc; /* Add border around the container */
  border-radius: 8px;
  padding: 10px; /* Add padding around each address */
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Enable vertical scrolling if content exceeds container height */
}

.address {
  border-bottom: 1px solid #ccc; /* Add border between addresses */
  padding-bottom: 10px; /* Add padding between addresses */
  margin-bottom: 10px; /* Add margin between addresses */
}

.address p {
  color: blue;
  font-weight: bold;
  font-size: 1.2rem;
}

.address:last-child {
  border-bottom: none; /* Remove border from last address */
  margin-bottom: 0; /* Remove margin from last address */
}

.address-frame h3 {
  margin-bottom: 5px; /* Add margin below each address */
  font-size: 1.5rem;
  margin: 10px;
}


.rates ul {
  list-style: none; /* Remove default list styles */
  padding: 0; /* Remove default padding */
  text-align: center;
}

.brand-text {
  font-size: 16px;
}

.rates-text {
  color: blue;
  font-size: 14px;
}


/* Mobile view styles */
@media only screen and (max-width: 600px) {
  .container {
    padding: 20px; /* Adjust padding for smaller screens */
  }

  .newrate-container,
  .rate-frame {
    width: 100%; /* Take up full width */
  }
  .addresses-container {
    width: 80%; /* Adjust width as needed */
  }
  .existing-info-icon{
    height: 20px;
    width: 20px;
    cursor: pointer;
    position: relative;
    left: 5vw;
  }
  .container .existingsite-data  {
    font-size: 7vw;
    margin-top: 2rem;
    font-weight: 600;
  }
}

@media only screen and (max-width: 775px) and (min-width:601px){
  .addresses-container {
    width: 50%; /* Take up full width */
  }
}
@media only screen and (max-width: 1342px) and (min-width:776px){
  .addresses-container {
    width: 50%; /* Take up full width */
  }
}
