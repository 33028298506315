.clientdiv{
    min-height: 550px;
    min-width: 100%;
    background-color: #fff;
    padding: 40px;
    padding: 50px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center; 
    border-radius: 8px;
}
.userdiv{
    height: 40px;
    width:12vw;
    border:2px solid #116C65;
    border-radius: 10px;
    align-items: center;
    text-align: center;
    color: #000;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    line-height: 3;
}
.userdiv .userdivInput{
  border: none;
  outline: none;
  text-align: center;
}
.clientdiv .crmdiv .CRM-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.CRM-container h1{
    font-size: 3vw;
    margin-top: 20px;
    word-wrap: nowrap;
    margin-left:-10px;
    /* box-shadow: 5px 5px 8px blue, 10px 10px 8px red, 15px 15px 8px green; */
}
.clientdiv .crmdiv .CRM-container .userdiv{
    margin-left: 80px;
    margin-top: 30px;
}
.clientdiv .crmdiv .CRM-container p{
    font-size: 2.5vh;
    color: #000;
    text-align: center;
    margin-left: 30px;
    margin-right: -40px;

}
.clientdiv .excla{
    color: blue;
    font-size: 30px;
    margin-top: 40px;
    margin-left: 10px;
}
.clientdiv .excla:hover{
    cursor: pointer;
    color: blueviolet;
}
/* serachbox css  */
.searchbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25vh; /* Adjust height as needed */
  }
  
  .searchbox {
    min-width: 200%; /* Takes full width of the container */
    height: 35px;
    position: relative;
    border-radius: 20px;
    border: 2px solid #000;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
 .clientdiv .myserach {
    width: 100%; /* Takes full width of the searchbox */
    padding: 10px;
    font-size: 16px;
    outline: none;
    border-radius: 20px;
    border: none;
    margin-left: 40px;
  }
  
  .suggestions {
    position: absolute;
    top: 100%;
    left: 0%;
    width: 100%;
    max-height: 200px; /* Adjust max-height as needed */
    overflow-y: auto; /* Enable scrolling if suggestions overflow */
    margin-top: 4px;
    padding: 0;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    list-style-type: none;
    display: flex;
  flex-wrap: wrap; /* Allow items to wrap onto multiple lines */
  }
  
  .suggestions li {
    padding: 10px;
    cursor: pointer;
    flex: 1 0 50%;
  }
  
  .suggestions li:hover {
    background-color: #f2f2f2;
  }
  .search-fa{
    font-size: 20px;
    position: absolute;
    top: calc(6% + 8px); /* Position directly below the input box */
    left: 4%;
  transform: translateX(-50%); /* Center horizontally */
  }
  /* section 3  */

/* section 4  */
.switch_container .crmSwitch{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 30px;
}
.switch_container .crmExclaicon{
  font-size: 30px;
  color: blue;
  margin-right: 10px;

}
.switch_container .crmExclaicon:hover{
  font-size: 30px;
  color: blueviolet;
  cursor: pointer;
}
.switch_container .crmswitchbutn{
  margin-left: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.switch_container .crmlogout{
  color: #000;
}
/* section3 crmboxflex1  */
.crmbox_container {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 1px solid #fff;
  padding: 20px 40px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -40px;
  margin-right: 50px;
  min-height: 500px; /* Add max height if needed */
  overflow-y: auto; /* Add scrollbar if content overflows */
}
.crmbox_container .crmboxflex1{
 background-color: cornsilk;
 border: 1px solid rgb(249, 162, 131);
 height: 300px;
 width: 20%;
 border-radius: 10px;
}

.crmbox_container .crmboxflex1 h1{
  font-size: 2vw;
  text-align: center;
}
.crmbox_container .crmboxflex1 .crmaddress,.crmstate,.crmactive{
  height: 40px;
  width: 70%;
  background:#fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px;
 border-radius: 10px;
 color: #fff;
 text-align: center;
 line-height: 2;
 margin-top: 10px;
 margin-bottom: 10px;
 font-size: 1vw;
 flex: 1;
 margin-left: 20px;
 align-items: center;
}


.crmbox_container .crmboxflex1 .crmInput{
  border: none;
  outline: none;
  text-align: center;
  color:blue;
  padding: 10px;
  text-decoration: underline;
  font-size: 1.1rem;
}
.crmbox_container .crmboxflex1 .crmInput::placeholder{
  color: #000;
}
.crmbox_container .crmboxflex1 .crmaddress{
  margin-top: 30px;
}
.crmbox_container .crmboxflex2 .crmBalance,.crmlastDispatch,.crmpending,.crmbank{
  height: 40px;
  width: 250%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px;
 border-radius: 10px;
 color: #000;
 text-align: center;
 line-height: 2;
 margin-top: 10px;
 margin-bottom: 10px;
 margin-left: -100px;
 font-size: 1vw;
 flex: 1;
}
.crmbox_container .crmboxflex2 .crmBalance,.crmlastDispatch,.crmpending,.crmbank{
  background-color: cornsilk;
}

.crmbox_container .crmboxflex2 .crmInput{
  border: none;
  outline: none;
  text-align: center;
  color:blue;
  padding: 10px;
  font-size: 1.1rem;
}
.crmbox_container .crmboxflex2 .crmInput::placeholder{
  color: #000;
}
.crmbox_container .crmboxflex2 .crmbank,.crmInput{
  cursor: pointer;
}
.crmbox_container .crmboxflex3 .crmcode,.crmbrandSupply,.crmcommodity,.crmorder,.crmpayment{
  height: 40px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px;
 border-radius: 10px;
 color: #fff;
 text-align: center;
 line-height: 2;
 margin-top: 10px;
 margin-bottom: 10px;
 font-size: 1vw;
 flex: 1;
}
.crmbox_container .crmboxflex3 .crmInput{
  border: none;
  outline: none;
  text-align: center;
  color:blue;
  padding: 10px;
  font-size: 1.1rem;
}
.crmbox_container .crmboxflex3 .crmInput::placeholder{
  color: #000;
}
.crmbox_container .crmboxflex3 .crmcode,.crmcommodity,.crmpayment,.crmbrandSupply ,.crmorder{
  background-color: cornsilk;

}

.bank-details-container {
  margin-top: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  max-height: 500px; /* Adjust as needed */
  overflow-y: auto; /* Add scrollbar if content overflows */
}

.bank-details-btn {
  display: block;
  margin: 5px 0;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.bank-details-btn:hover {
  background-color: #0056b3;
}

.bank-details-info {
  margin-top: 10px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.crmbox_container textarea{
  background-color: none;
  outline: none;
  overflow-x: auto;
  overflow-y: hidden;
 margin-top: -7px;
 height: 30px;
 width: 90%;
 background-color: cornsilk;
 font-size: 1.5vw;
}

  @media only screen and (max-width: 768px) {
    .userdiv{
      min-width:40%;
      position: relative;
      left: -15%;
     align-items: center;
  }
  .clientdiv .excla{
   margin-top: 35px;
   position: relative;
   right: 15%;
  }

    .suggestions {
      max-width: 150%; /* Adjust for smaller screens */

    }
    .searchbox {
      min-width: 100%; /* Takes full width of the container */
      height: 35px;
      position: relative;
      border-radius: 20px;
      border: 2px solid #000;
      align-items: center;
      margin-right: 50px;
    }
    .search-fa{
      font-size: 20px;
      position: absolute;
      top: calc(5% + 8px); /* Position directly below the input box */
      left: 5%;
      transform: translateX(-50%); /* Center horizontally */
    }
    .CRM-container h1{
      font-size: 4.5vw;
      position: relative;
      left: -15px;
  }
  /* section 3  */
  .crmbox_container {
    flex-direction: column;
    align-items: center;
  }
  .crmbox_container .crmboxflex1{
    background-color: cornsilk;
    border: 1px solid rgb(249, 162, 131);
    height: 300px;
    width: 60%;
    border-radius: 10px;
   }
   .crmbox_container .crmboxflex1 h1{
    font-size: 4vw;
    text-align: center;
    margin-top: 10px;
  }
  .crmbox_container .crmboxflex1 .crmaddress,.crmstate,.crmactive{
   margin-left: 50px;
  }
  .crmbox_container .crmboxflex1 .crmInput{
    font-size: 2vw;
  }
  .crmbox_container .crmboxflex2 .crmInput{
    font-size: 2vw;
  }
  .crmbox_container .crmboxflex3 .crmInput{
    font-size: 2vw;
  }
  .crmboxflex2{
    z-index: 999;
  }
 
   /* section 4  */
.switch_container .crmlogout{
  font-size: 20px;
}
.switch_container .crmSwitch{
  font-size: 30px;
  position: relative;
  left: -40%;
}

}
@media only screen and (max-width: 600px) {
  .userdiv{
    min-width:40%;
    position: relative;
    left: -22%;
   align-items: center;
}
.clientdiv .excla{
 margin-top: 35px;
 position: relative;
 right: 24%;
}

  .suggestions {
    max-width: 150%; /* Adjust for smaller screens */

  }
  .searchbox {
    min-width: 100%; /* Takes full width of the container */
    height: 35px;
    position: relative;
    border-radius: 20px;
    border: 2px solid #000;
    align-items: center;
    margin-right: 60px;
    margin-left: -10px;
  }
  .search-fa{
    font-size: 20px;
    position: absolute;
    top: calc(5% + 8px); /* Position directly below the input box */
    left: 5%;
    transform: translateX(-50%); /* Center horizontally */
  }
  .CRM-container h1{
    font-size: 4.5vw;
    position: relative;
    left: -15px;
}
/* section 3  */
.crmbox_container {
  flex-direction: column;
  align-items: center;
}
.crmbox_container .crmboxflex1{
  background-color: cornsilk;
  border: 1px solid rgb(249, 162, 131);
  height: 300px;
  width: 60%;
  border-radius: 10px;
  margin-left: 20px;
 }
 .crmbox_container .crmboxflex1 h1{
  font-size: 4vw;
  text-align: center;
}
.crmbox_container .crmboxflex1 .crmaddress,.crmstate,.crmactive{
 margin-left: 20px;
}
.crmbox_container .crmboxflex1 .crmInput{
  font-size: 2vw;
}
.crmbox_container .crmboxflex2 .crmInput{
  font-size: 2vw;
}
.crmbox_container .crmboxflex3 .crmInput{
  font-size: 2vw;
}
.crmbox_container .crmboxflex2 .crmBalance,.crmlastDispatch,.crmpending,.crmbank{
  min-width: 100%;
  margin-left: -60px;
}

 /* section 4  */
.switch_container .crmlogout{
font-size: 20px;
}
.switch_container .crmSwitch{
font-size: 30px;
position: relative;
left: -40%;
}

}

@media only screen and (max-width: 350px) {
  .userdiv{
    min-width:30%;
    position: relative;
    left: -15%;
   align-items: center;
}
.clientdiv .excla{
 margin-top: 35px;
 position: relative;
 right: 18%;
}

  .suggestions {
    max-width: 150%; /* Adjust for smaller screens */

  }
  .searchbox {
    min-width: 100%; /* Takes full width of the container */
    height: 35px;
    position: relative;
    border-radius: 20px;
    border: 2px solid #000;
    align-items: center;
    margin-right: 50px;
    margin-left: -20px;
  }
  .search-fa{
    font-size: 20px;
    position: absolute;
    top: calc(5% + 8px); /* Position directly below the input box */
    left: 5%;
    transform: translateX(-50%); /* Center horizontally */
  }
  .CRM-container h1{
    font-size: 4.5vw;
    position: relative;
    left: -15px;
}
/* section 3  */
.crmbox_container {
  flex-direction: column;
  align-items: center;
}
.crmbox_container .crmboxflex1{
  background-color: cornsilk;
  border: 1px solid rgb(249, 162, 131);
  height: 300px;
  width: 60%;
  border-radius: 10px;
  margin-left: 20px;
 }
 .crmbox_container .crmboxflex1 h1{
  font-size: 4vw;
  text-align: center;
}
.crmbox_container .crmboxflex1 .crmaddress,.crmstate,.crmactive{
 margin-left: 20px;
}
.crmbox_container .crmboxflex1 .crmInput{
  font-size: 2vw;
}
.crmbox_container .crmboxflex2 .crmInput{
  font-size: 2vw;
}
.crmbox_container .crmboxflex3 .crmInput{
  font-size: 2vw;
}
.crmbox_container .crmboxflex2 .crmBalance,.crmlastDispatch,.crmpending,.crmbank{
  min-width: 100%;
  margin-left: -50px;
}

 /* section 4  */
.switch_container .crmlogout{
font-size: 20px;
}
.switch_container .crmSwitch{
font-size: 30px;
position: relative;
left: -40%;
}

}
@media only screen and (max-width: 1000px) and (min-width:769px) {
  .userdiv{
    min-width:40%;
    position: relative;
    left: -15%;
   align-items: center;
}
.clientdiv .excla{
 margin-top: 35px;
 position: relative;
 right: 15%;
}

  .suggestions {
    max-width: 150%; /* Adjust for smaller screens */

  }
  .searchbox {
    min-width: 100%; /* Takes full width of the container */
    height: 35px;
    position: relative;
    border-radius: 20px;
    border: 2px solid #000;
    align-items: center;
    margin-right: 50px;
  }
  .search-fa{
    font-size: 20px;
    position: absolute;
    top: calc(5% + 8px); /* Position directly below the input box */
    left: 5%;
    transform: translateX(-50%); /* Center horizontally */
  }
  .CRM-container h1{
    font-size: 4.5vw;
    position: relative;
    left: -15px;
}
/* section 3  */
.crmbox_container {
  flex-direction: column;
  align-items: center;
}
.crmbox_container .crmboxflex1{
  background-color: cornsilk;
  border: 1px solid rgb(249, 162, 131);
  height: 300px;
  width: 60%;
  border-radius: 10px;
 }
 .crmbox_container .crmboxflex1 h1{
  font-size: 4vw;
  text-align: center;
  margin-top: 10px;
}
.crmbox_container .crmboxflex1 .crmaddress,.crmstate,.crmactive{
 margin-left: 50px;
}
.crmbox_container .crmboxflex2 .crmBalance,.crmlastDispatch,.crmpending,.crmbank{
  margin-right: -0px;
}

.crmbox_container .crmboxflex1 .crmInput{
  font-size: 2vw;
}
.crmbox_container .crmboxflex2 .crmInput{
  font-size: 2vw;
}
.crmbox_container .crmboxflex3 .crmInput{
  font-size: 2vw;
}
 /* section 4  */
.switch_container .crmlogout{
font-size: 20px;
}
.switch_container .crmSwitch{
font-size: 30px;
position: relative;
left: -40%;
}
.crmboxflex2{
  z-index: 999;
}
}
@media only screen and (width: 540px) {
  .crmbox_container .crmboxflex2 .crmBalance,.crmlastDispatch,.crmpending,.crmbank{
  margin-right: -20px;
}
}
@media only screen and (max-width:400px) and (min-width:300px) {
  .crmbox_container .crmboxflex2 .crmBalance,.crmlastDispatch,.crmpending,.crmbank{
    margin-right: 20px;
  }
}