.machinery-form {
  background-color: #f5f5f5;
  padding: 70px 50px 40px 30px;
  border: 1px solid #1ab0a4;
  border-radius: 10px;
  width: 40%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 20px;
}

.machinery-form .heading {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  margin-top: -40px;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
}

select,
input[type="text"],
input[type="date"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 10px;
}

button[type="submit"] {
  background-color: #116C65;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  margin: 0 auto; /* Center horizontally */
  display: block; /* Ensures it takes full width */
}


button[type="submit"]:hover {
  background-color: #1ab0a4;
}

p.success-message {
  font-size: 16px;
  color: #4caf50; /* Green color for success message */
}
@media only screen and (max-width: 767px) {
.machinery-form {
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-radius: 3px;
  padding-left: 40px;
  padding-right: 40px;
  /* margin-left: -1px;
  margin-right: 20px; */
}

}
@media only screen and (max-width: 454px) {
  .machinery-form {
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 3px;
    padding-left: 20px;
    padding-right: 20px;
    /* margin-left: 20px;
    margin-right: -40px; */
  }
  
  }
  @media only screen and (max-width: 380px) {
    .machinery-form {
      width: 100%;
      margin-top: 3rem;
      margin-bottom: 3rem;
      border-radius: 3px;
      padding-left: 20px;
      padding-right: 20px;
      /* margin-left: 15px;
      margin-right: -40px; */
    }
    
    }
    @media only screen and (max-width: 350px) {
      .machinery-form {
        width: 100%;
        margin-top: 3rem;
        margin-bottom: 3rem;
        border-radius: 3px;
        padding-left: 20px;
        padding-right: 20px;
        /* margin-left: 10px;
        margin-right: -40px; */
      }
      
      }
@media only screen and (width: 768px) and (height:1024px) {
.machinery-form {
  width: 50vw;
  
}
  
}
@media only screen and (max-width: 767px) and (min-width:455px) {
  .machinery-form {
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 3px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: 100px;
  }
}
@media only screen and (max-width: 764px) and (min-width:700px) {
  .machinery-form {
    width: 100%;
     margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 3px;
    margin-left: 120px;
    margin-right: -70px;  

  }
}

@media only screen and (max-width: 680px) and (min-width:500px) {
  .machinery-form {
    width: 50%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 3px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: 100px;
    margin-right: 70px;

  }
}
@media only screen and  (max-width:499px) {
  .machinery-form {
    width: 50%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 3px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: 75px;
    margin-right: 50px;
  }
}
@media only screen and  (max-width:435px) {
  .machinery-form {
    width: 50%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 3px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: 40px;
    margin-right: 50px;
  }
}
@media only screen and  (width:375px) {
  .machinery-form {
    width: 50%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 3px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: 30px;
    margin-right: 50px;
  }
}
@media only screen and  (width:414px) {
  .machinery-form {
    width: 50%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 3px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: 45px;
    margin-right: 50px;
  }
}
@media only screen and  (width:390px) {
  .machinery-form {
    width: 50%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 3px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: 40px;
    margin-right: 50px;
  }
}
@media only screen and  (width:430px) {
  .machinery-form {
    width: 50%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 3px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: 60px;
    margin-right: 50px;
  }
}
@media only screen and  (width:412px) {
  .machinery-form {
    width: 50%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 3px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: 60px;
    margin-right: 50px;
  }
}
@media only screen and  (width:360px) {
  .machinery-form {
    width: 50%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 3px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media only screen and (min-width: 800px) and (max-width:1300px) {
  .machinery-form {
    width: 50vw;
  }
  }
  @media only screen and (max-width: 800px) and (min-width:681px) {
    .machinery-form {
      width: 50%;
      margin-top: 3rem;
      margin-bottom: 3rem;
      border-radius: 3px;
      padding-left: 40px;
      padding-right: 40px;
      margin-left: 100px;
      margin-right: 70px;
  
    }
  }
  @media only screen and (max-width: 1300px) and (min-width:1171px) {
    .machinery-form {
      width: 70%;
      margin-top: 3rem;
      margin-bottom: 3rem;
      border-radius: 3px;
    }
  }