/* MyPage.css */
.vendor-page {
  font-family: 'Arial', sans-serif;
  padding: 20px;
  position: relative; 
  padding-top: 80px;
}

.vendor-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 81%;
  background: linear-gradient(to right top, #20aee6 50%, transparent 50%);
  border-radius: 0 0 20px 20px; /* Adjust border-radius for a curved effect */
  z-index: -1;
}


.vendor_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vendor-page h1 {
  font-size: 5rem;
  margin: 10px 0;
  color: #fff;
  margin-top: -60px;
}

.text-container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.vendor-page h2 {
  color: black;
  margin: 10px 0;
  font-size: 2rem;
}

.vendor-page button {
  width: 180px;
  padding: 15px;
  border-radius: 50px;
  background-color: #116C65;
  color: #fff;
  margin-top: 10px;
  font-size: 25px;
  font-weight: bold;
  transition: transform 0.3s ease; /* Add a smooth transition effect */
}

.vendor-page button:hover {
  transform: translateY(-5px);
  background-color: #1ab0a4; /* Retain the same background color on hover */
}

.header-image {
  width: auto;
  height: 500px;
  border-radius: 10px;
}

/* vendor.css */
.vendor-steps-container {
  padding: 40px;
  background-color: #fff;
  text-align: center;
  border-radius: 15px; /* Add rounded borders */
  max-width: 1000px; /* Set a maximum width */
  margin: 0 auto; /* Center the container by setting margin auto */
}


.vendor-steps-container h2 {
  font-size: 2.5rem;
  margin-bottom:50px;
}

.steps {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.step {
  width: 45%; /* Adjust width as needed */
  margin-bottom: 20px;
  color: #333;
}

/* vendor.css */
.step p {
  color: #333;
  font-weight: bold; /* Make the text bold */
}


.step img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

/* New styles for benefits-container */
.benefits-container {
  padding: 40px;
  text-align: center;
  border-radius: 15px;
  margin: 20px auto; /* Add margin to separate it from the previous container */
  max-width: 1200px; /* Set a maximum width for the container */
}

.benefits-container h2 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.benefits-list {
  list-style-type: none; /* Remove default list bullets */
  text-align: center; /* Center-align the list items */
  padding: 0; /* Remove default padding */
}

.benefits-list li {
  margin-bottom: 10px;
  text-align: left; /* Align the text within each list item to the left */
  margin-left: auto; /* Center-align the list content */
  margin-right: auto; /* Center-align the list content */
  max-width: 900px; /* Set a maximum width for the list content */
}
@media only screen and  (max-width:1650px){
.vendor-page h1 {
  margin-bottom: 45px;
  font-size: 4.5rem;
}
  
}
@media only screen and  (max-width:1000px){
  .vendor_header {
    display: block;
  }
  .header-image {
    width: 100%;
    margin-top: 4rem;
  }
  .vendor-page h1 {
    font-size: 3.7rem;
    margin-top: 6rem;
   margin-right: 30rem;
  }
  
}

@media only screen and  (max-width:776px){
  .vendor_header {
    display: block;
  }
  .header-image {
    width: 100%;
    margin-top: 4rem;
  }
  .vendor-page h1 {
    font-size: 2.9rem;
    margin-top: 7rem;
   margin-right: 30rem;
  }
  .vendor-page .text-container h2{
    font-size: 1.7rem;
  }
}
@media only screen and  (max-width:485px){
  .vendor_header {
    display: block;
  }
  .header-image {
    width: 100%;
    margin-top: 4rem;
  }
  .vendor-page h1 {
    font-size: 2.3rem;
    margin-top: 12rem;
   margin-right: 30rem;
  }
  .vendor-page .text-container h2{
    font-size: 1.7rem;
  }
}
@media only screen and  (max-width:370px){
  .vendor_header {
    display: block;
  }
  .header-image {
    width: 100%;
    margin-top: 4rem;
  }
  .vendor-page h1 {
    font-size: 2.5rem;
    margin-top: 15.5rem;
   margin-right: 30rem;
  }
  .vendor-page .text-container h2{
    font-size: 1.7rem;
  }
}
@media only screen and  (max-width:692px) and (min-width:500px) {
  .vendor-page h1 {
    font-size: 2.4rem;
    margin-top: 6rem;
   margin-right: 30rem;
  }
}

@media only screen and  (max-width:1252px) and (min-width:1001px) {
  .vendor_header h1{
    font-size: 3rem;
  margin-bottom: 9rem;

  }
  .header-image {
    width: 100%;
    margin-top: 6rem;
  }
}
@media only screen and  (min-width:1253px) and (max-width:1542px) {
  .vendor_header h1{
    font-size: 3rem;
  
  }
  .vendor-page .text-container h2{
    font-size: 1.7rem;
  }
}