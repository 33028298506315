/* Profile container */
.profile-container {
  position: relative; /* Ensure relative positioning for absolute positioning of child elements */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100%;
  height: 80vh; /* Take up full viewport height */
  background-color: #00568c;
  background-image: url('/public/Images/image.png'); /* Path to your image */
  background-size: cover; /* Set the background size to contain */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  background-position: center; /* Center the background image */
}

/* Profile frame */
.profile-frame {
  max-width: auto;
  padding: 20px;
  margin-top: 20px; /* Adjusted top margin */
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.666); /* Transparent background */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #fff; /* White text */
}

/* Profile completion message */
.profile-completion-msg {
  position: absolute; /* Position absolute */
  top: 0; /* Align to the top of the profile container */
  left: 50%; /* Align horizontally */
  transform: translateX(-50%); /* Center horizontally */
  z-index: 1; /* Ensure it's above other elements */
  margin-top: 20px; /* Adjust top margin */
  padding: 10px;
  text-align: center;
  color: blue;
  font-size: 20px;
  white-space: nowrap;
  margin-top: 1.5rem;
}


/* Profile info section */
.profile-info {
  text-align: center;
}

/* Profile image container */
.profile-image-container {
  margin-bottom: 20px;
}

/* Profile image */
.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 5px solid #1ab0a4;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.profile-image:hover {
  transform: scale(1.1); /* Increasing the scale to 1.1 on hover (zoom effect) */
}

.input-label {
  margin-bottom: 20px;
  color: #000;
}

.input-label label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-label input {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.332);;
}

.input-container {
  display: flex;
  align-items: center;
}


/* Profile details */
.profile-details p {
  margin-bottom: 8px;
  color: #333;
  font-size: 20px;
  p {
    margin: 5px 0; /* Optional: Adjust margin for spacing between paragraphs */
  }
  p strong {
    font-weight: bold;
    font-size: 2rem; /* Optional: Adjust font size for labels */
  }
  p span {
    font-weight: bold;
  }
}


/* Style for the buttons */
.button-common {
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.logout {
  background-color: #116C65;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
}

.logout:hover {
  background-color: #1ab0a4;
}

.save, .delete {
  padding: 8px 16px;
  margin: 10px;
  background-color: #116C65;
  color: #fff;
  width: 1.5in;
}

.save:hover, .delete:hover {
  background-color: #1ab0a4;
}


