.address-container {
    position: relative; /* Make the container relative */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    background-image: url('/public/Images/ratebg.jpg');
    background-size: cover;
    background-position: center;
  }
  
  .site-container {
    width: 400px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Drop shadow */
    position: relative; /* Make the container relative */
  }
  
  .site-frame {
    text-align: center;
  }
  
.address-container h1 {
  text-align: center;
  margin-top: 50px; /* Adjust the top margin */
  color: #116C65;
  font-size: 2.5vw;
  position: absolute; /* Position absolutely */
  top: 0; /* Align to the top */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center precisely */
}
.address-container .newsite-data span {
  font-size: 2vw;
}
  
  
  label {
    display: block;
    margin-bottom: 5px;
    margin: 10px;
    font-weight: bold;
    text-align: left;
  }
  
  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #116C65; /* Blue button */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .success-message {
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Drop shadow */
    position: absolute; /* Position absolutely */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center precisely */
    z-index: 1;
  }
  
  .hidden {
    display: none;
  }
  .address-info-icon{
    height: 1.1vw;
    width: 1.1vw;
    object-fit: cover;
    cursor: pointer;
  }
  /* Mobile view styles */
  @media only screen and (max-width: 600px) {
    .address-container {
      padding: 20px; /* Adjust padding for smaller screens */
    }

    .address-container h1 {
      width: 100%; /* Take up full width */
      font-size: 5vw;
      margin-left: -1rem;
    }
    .address-container .newsite-data span {
      font-size: 4.5vw;
    }
    .address-info-icon{
      height: 4.5vw;
      width: 4.5vw;
      object-fit: cover;
      margin-top: 2rem;
    }
  
    .site-container,
    .site-frame {
      width: 100%; /* Take up full width */
    }
    .site-frame{
      width: 92%;
    }
    .address-container {
      width: 100%; /* Take up full width */
    }
    .site-frame, label{
      font-size: 4vw;
    }
  }
  @media only screen and (min-width: 601px) and (max-width:800px) {
    .site-container{
      margin-top: 7rem;
    }
    .address-container h1 {
      width: 100%; /* Take up full width */
      font-size: 4vw;
      margin-left: 0rem;
    }
    .address-container .newsite-data span {
      font-size: 3.5vw;
    }

    .address-info-icon{
      height: 3.5vw;
      width: 3.5vw;
      object-fit: cover;
    }
    .site-frame, label{
      font-size:1rem;
    }
  }
  @media only screen and (min-width: 600px) {
    .site-container{
      margin-top: 3rem;
    }

  }

  @media only screen and (min-width: 801px) and (max-width:1385px) {
    .site-container{
      margin-top: 6rem;
    }
    .address-container h1 {
      padding-top: 18px;
    }
    .address-info-icon{
      height: 2vw;
      width: 2vw;
      object-fit: cover;
    }
  }
  