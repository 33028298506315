/* App.css */

/* Apply basic styles to the body */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Apply styles to the entire app container */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the app container takes up at least the full viewport height */
}

/* Header.css */
.header-container {
  position: absolute;
  width: 100%;
}

/* App.css */
.container {
  padding-top: 100px; /* Adjust as needed to match the height of the header */
  position: relative;
}


/* Apply styles to the main content area */
.main-content {
  flex-grow: 1; /* Allow the main content area to grow to fill remaining space */
  margin-top: 800px; /* Adjust as needed to create space for the header */
}
.msg-container{
  display: flex;
  justify-content: space-between;
}
.collectchat-button-wrapper{
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
  border: none;
  outline: none;
}

.collectChat img{
  width: 80px;
  height: 50px;
  cursor: pointer;
  object-fit: cover;
}

.chat-text p {
  font-weight: bold;
  color: rgb(255, 0, 0);

}


