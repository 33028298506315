
.loan-form-container {
  width: 90%;
  margin: auto;
  padding: 20px;
  border: 2px solid #1ab0a4; /* Border color */
  border-radius: 10px; /* Rounded corners */
  overflow-x: hidden;
  max-width: 100%;
  box-sizing: border-box; /* Add this */
}

.loan-form-container .title {
  text-align:center;
  font-size: 30px;
  margin-bottom: 30px;
  color: #116C65;
}


.loan-label {
  font-weight: bold;
  color: black;
  text-align: left;
  margin-left: 30px;
  margin-top: 15px;
}

.loan-select,
.loan-input,
.loan-button {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc; /* Input border */
  border-radius: 5px; /* Rounded corners */
  color: black;
  max-width: 100%;
  box-sizing: border-box; /* Add this */
}

.loan-input[type="text"]{
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc; /* Input border */
  border-radius: 5px; /* Rounded corners */
  color: black;
  max-width: 100%;
  box-sizing: border-box;
}

.loan-button {
  background-color: #116C65; /* Button background color */
  color: white; /* Button text color */
  cursor: pointer;
  margin:15px;
}

.loan-button:hover {
  background-color: #1ab0a4; /* Darker color on hover */
}

.success-message,
.error-message {
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
}

/* Media query for smaller screens */
@media only screen and (max-width: 768px) {
  .loan-form {
    grid-template-columns: 1fr;
    overflow-x: hidden;
    width: 100%;
  }
  .loan-form-container {
    overflow-x: hidden;
    width: 100%;
  }
}
