/* Navbar.css */

/* General styles for the Navbar */
nav {
  background-color: #fff; /* Adjust background color */
  padding: 10px 0; /* Adjust padding as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for styling */
  
}

.nav-links {
  padding: 0; /* Remove padding to reduce extra space */
  margin: 0; /* Remove margin to reduce extra space */
  display: flex; /* Flexbox for horizontal alignment */
  justify-content: space-around; /* Distribute items evenly */
  list-style: none; /* Remove default list styles */
  align-items: center; /* Center items vertically */
}

.nav-links li {
  position: relative;
}

.nav-links a {
  text-decoration: none;
  color: #116C65;
  font-size: 20px;
  padding: 10px 20px; /* Adjust padding for better spacing */
  display: block; /* Ensure each link occupies a full line */
  font-weight: bold;
}

.nav-links a:hover {
  text-decoration: underline;
  color: #1ab0a4;
}

.logout-button {
  text-decoration: none;
  color: #fff;
  background-color: #4CAF50;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
}

.logout-button:hover {
  background-color: #006400;
}

/* Dropdown styles */
.dropdown {
  position: relative;
  display: inline-block;
  color: #116C65;
  font-size: 20px;
  padding: 8px 16px;
  font-weight: bold;
}

.dropdown:hover {
  text-decoration: underline;
  color: #1ab0a4;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
  left: 0; 
  width: max-content;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); 
}

.dropdown-content a {
  color: #116C65;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content.specific-dropdown {
  left: 150px;
  top: -2px;
}

.dropdown-content.show {
  display: block;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    cursor: pointer;
    margin: 10px;
    position: absolute; /* Change position to absolute */
    top: 30px;
    left: 20px; /* Adjust left position as needed */
    z-index: 1000;
  }

  .menu-toggle .bar {
    width: 25px;
    height: 3px;
    background-color: #116C65;
    margin: 5px 0;
    transition: 0.4s;
  }

  .nav-links {
    display: none;
    flex-direction: column; /* Stack items vertically */
    width: 100%; /* Full width for mobile view */
    height: auto;
    padding: 0; /* Remove padding to reduce extra space */
    position: fixed;
    top: 70px;
    left: 0;
    background-color: #fff; /* Adjust background color */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2); /* Add shadow */
    z-index: 999; /* Ensure it's above other content */
  }
  .dropdown:hover {
    color: #116C65;
  }

  .nav-links.show {
    display: flex;
  }

  .nav-links li {
    margin: 10px 0; /* Adjust for vertical spacing in mobile view */
  }

  /* Adjustments for dropdowns in responsive view */
  .dropdown.clicked .dropdown-content {
    display: block;
  }

  .dropdown.clicked {
    color: #116C65;
  }

  .dropdown-content {
    display: none;
    position: static;
    z-index: 1;
    color: #116C65;
    padding: 8px 0;
  }

  .dropdown-content a {
    color: #116C65;
    padding: 8px 16px;
    text-decoration: none;
    display: block;
  }
}
  

/* General Navbar Styles */
nav {
  background-color: #fff;
  padding: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Navbar Links */
.nav-links {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  list-style: none;
  align-items: center;
}

.nav-links li {
  position: relative;
}

.nav-links a {
  text-decoration: none;
  color: #116C65;
  font-size: 18px;
  padding: 8px 12px;
  font-weight: bold;
  white-space: nowrap; /* Prevent text wrapping */
}

.nav-links a:hover {
  text-decoration: underline;
  color: #1ab0a4;
}

.logout-button {
  text-decoration: none;
  color: #fff;
  background-color: #4CAF50;
  padding: 8px 16px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
}

.logout-button:hover {
  background-color: #006400;
}

/* Dropdown Styles */
.dropdown {
  position: relative;
  display: inline-block;
  color: #116C65;
  font-size: 18px;
  padding: 8px 16px;
  font-weight: bold;
}

.dropdown:hover {
  text-decoration: underline;
  color: #1ab0a4;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
  left: 0;
  width: max-content;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.dropdown-content a {
  color: #116C65;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
}

/* Mobile View - Toggle Menu */
.menu-toggle {
  display: none;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .dropdown span{
    position: absolute;
    margin-top: 6px;
   }
  .menu-toggle {
    display: block;
    cursor: pointer;
    margin: 10px;
    position: absolute;
    top: 30px;
    left: 20px;
    z-index: 1000;
  }

  .menu-toggle .bar {
    width: 25px;
    height: 3px;
    background-color: #116C65;
    margin: 5px 0;
    transition: 0.4s;
  }

  /* Navbar Links in Mobile View */
  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    position: fixed;
    top: 60px;
    left: 0;
    background-color: #fff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    z-index: 999;
  }

  .nav-links.show {
    display: flex;
  }

  .nav-links li {
    margin: 10px 0;
  }

  .dropdown-content {
    position: static;
    width: 100%;
  }
}

/* Adjust font sizes and spacing for different screen sizes */
@media only screen and (min-width: 769px) and (max-width: 1100px) {
  nav {
    padding: 30px 10px;
  }
  .dropdown span{
    position: absolute;
    margin-top: 6px;
   }
  .menu-toggle {
    display: block;
    cursor: pointer;
    margin: 10px;
    position: absolute;
    top: 50px;
    left: 20px;
    z-index: 1000;
  }

  .menu-toggle .bar {
    width: 25px;
    height: 3px;
    background-color: #116C65;
    margin: 5px 0;
    transition: 0.4s;
  }

  /* Navbar Links in Mobile View */
  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    position: fixed;
    top: 60px;
    left: 0;
    background-color: #fff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    z-index: 999;
  }

  .nav-links.show {
    display: flex;
  }

  .nav-links li {
    margin: 10px 0;
  }

  .dropdown-content {
    position: static;
    width: 100%;
  }
}
@media only screen and (min-width: 1101px) and (max-width: 1106px) {
  .dropdown span{
    position: absolute;
    margin-top: 6px;
   }
}
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .nav-links a,
  .dropdown {
    font-size: 16px;
  }

  .nav-links li {
    margin: 0 10px;
  }
}

