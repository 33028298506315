.steel-Form {
  background-color: #f5f5f5;
  padding: 70px 50px 40px 30px;
  border: 1px solid #1ab0a4;
  border-radius: 10px;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 20px;
}

.steel-Form .heading {
  font-size: 30px;
  margin-bottom: 20px;
  text-align: center;
  margin-top: -40px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
}

input[type="text"],
input[type="number"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 10px;
}

.steel-Form .submit-button {
  background-color: #116C65;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.steel-Form .submit-button:hover {
  background-color: #1ab0a4;
}

p.success-message {
  font-size: 16px;
  color: #4caf50; /* Green color for success message */
  margin-top: 10px;
}
 /* Adding media query  */
@media only screen and (max-width: 700px) {
  .steel-Form {
    padding: 70px 50px 40px 30px;
    border-radius: 10px;
    max-width: 500px;
    margin: 0 auto;
  }
.steel-Form .submit-button {
  width: 85%;
}
}
@media only screen and (max-width: 768px) {
  .steel-Form {
    margin-bottom: 40px;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 768px) {
  .steel-Form {
    margin-bottom: 40px;
  }
}
