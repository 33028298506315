.Login_bg {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100%;
  height: 80vh; /* Take up full viewport height */
  background-color: #116C65;
  background-image: url('/public/Images/image.png'); /* Path to your image */
  background-size: cover; /* Set the background size to contain */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  background-position: center; /* Center the background image */
}

/* Add this to your Login.css file */
#phoneNumber::placeholder {
  color: #484848; /* Change this color to your preferred placeholder color */
}

.frame {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  width: 100%;
  max-height: 400px; /* adjust the height to your liking */
  overflow-y: auto;
 
  border-radius: 8px;
  border: 1px solid #ddd;
   background-color: rgba(255, 255, 255, 0.666); /*Transparent background */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #333; /* White text */
   margin-bottom: 10vh;
  margin-top: 16vh; 
}

.login-container h1 {
  margin: 1%;
  color: #333;
  text-align: center;
  font-size: 2.5rem;
}

.login-form {
  margin-top: -6%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.input-group {
  margin: 15px auto; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-group label {
 
  margin: 8px auto; /* Center-align with auto margins */
  font-size: 1.5rem;
}

.input-group input {
  display: flex;
  align-items: center;
  border: 1px solid #1ab0a4;
  border-radius: 4px;
}
.input-group #otp{
  border: 1px solid #1ab0a4;
}
.phone-input {
  display: flex;
  align-items: center;
  border: 1px solid #116C65;
  border-radius: 4px;
}

.phone-input select,
.phone-input input {
  border: none;
  outline: none;
  padding: 8px;
  font-size: 18px;
  color: #333;
}

.phone-input select {
  width: auto;
  margin: 8px;
}

.phone-input input {
  flex: 1;
  width: auto;
 
  text-align: start;
  margin-top: 0ch;
  position: relative;
  top: 5px;
}

.login-form p {
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 2%;
  color: #333;
}

.login-form a {
  color: blue;
}

.custom-select {
  width: 500px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.332);
  color: #333;
}

.optgroup {
  background-color: #f5f5f5;
}

.option {
  padding: 5px 10px;
}

.option:hover {
  background-color: #ddd;
}

.otp-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #116C65;
  color: #fff;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.otp-button:hover {
  background-color: #1ab0a4;
}

.error-message p {
  color: red;
  margin: 0;
  font-size: 16px;
}

.recaptcha-container {
  margin-bottom: 50px;
  display: block;
}
.error-message-container {
  max-width: 300px; /* adjust the width to your liking */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.error-message {
  color: red; /* adjust the color to your liking */
  font-size: 14px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .frame {
    margin: 10px;
  }

  .login-container {
    padding: 10px;
  }

  .login-container h1 {
    margin: 10px;
    font-size: 1.5rem;
  }

  .phone-input select {
    width: 25%;
    font-size: 18px;
  }

  .phone-input input {
    padding: 8px;
    font-size: 18px;
    width: 60%;
  }

  .otp-button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .error-message p {
    font-size: 14px;
  }

  .recaptcha-container {
    margin-bottom: 20px;
  }
}
