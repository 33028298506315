/* EnquiryForm.css */

.enquiry-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px 50px 40px 30px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.enquiry-container h1 {
  margin: 20px;
  color: #116C65;
  font-size: 5vh;
}

.enquiry-container .form-group {
  margin-bottom: 15px;
  text-align: left;
}

.enquiry-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.enquiry-container select,
.enquiry-container input {
  width: 100%;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 5px;
}

.enquiry-container button {
  background-color: #116C65;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  display: inline-block;
  font-weight: bold; /* Add bold font weight */
  text-transform: uppercase; /* Convert text to uppercase */
  letter-spacing: 1px;
}

.enquiry-container button:hover {
  background-color: #1ab0a4;
}

.enquiry-container .success-message {
  color: green;
  margin: 10px 0;
  text-align: center;
}
@media only screen and (max-width:768px) {
  .enquiry-container .submit-button{
    width: 100%;
  }
  .enquiry-container .heading{
    font-size: 3.7vh;
  }
  .enquiry-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px 50px 40px 30px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .enquiry-container h1 {
    margin: 20px;
    color: #116C65;
    margin-left: -10px;
    margin-right: -10px;
  }
}
