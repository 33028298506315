
/* landingPage.css */

.landing-page {
    position: relative;
  }
  
  .header-1 {
    position: relative;
    text-align: center;
    color: white;
    
  }
  
  .bg-image {
    width: 100%;
    height: 645px;
    object-fit: cover;
    background-size: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 99%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    overflow: hidden;
  }
  
  .header-2 {
    position: absolute;
    top: 50%;
    left: 49%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #ffffff;
    z-index: 2;
  }
  
  .header-2 h1 {
    font-size: 4rem; /* Adjust heading font size */
    color: #fff;
    align-items: center;
  }
  
  .header-2 p {
    font-size: 1.8rem; /* Adjust paragraph font size */
    color: #ffff;
   
  }

  .button-container {
    display: flex;
    justify-content: center; /* Center the buttons horizontally */
    align-items: center; /* Center the buttons vertically */
    gap: 20px; /* Add space between buttons */
    padding: 10px;
  }
  
  .entry_button, .contact_button {
    width: 200px;
    height: 50px;
    border-radius: 25px; /* Half of the button height for a curved border */
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 30px;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for background and transform */
    padding: 0 20px; 
  }
  
  /* Entry Button Styles */
  .entry_button {
    background-color: #1ab0a4;
    color: #ffffff; /* White text color */
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    
  }
  
  .entry_button:hover {
    background-color: #116C65; 
  }
  
  .entry_button:active {
    background-color: #1ab0a4; /* Even darker blue for active state */
    transform: scale(0.98); /* Slightly decrease size on active */
  }
  
  .entry_button:focus {
    outline: none; /* Remove default focus outline */
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Custom focus outline */
  }
  
  /* Contact Button Styles */
  .contact_button {
    border: 2px solid #1ab0a4; /* Blue border */
    background-color: #1ab0a4; /* Transparent background */
    color: #fff; /* Blue text color */
  }
  
  .contact_button:hover {
    color: rgba(8, 251, 52, 0.944); /* Change text color on hover */
    border-color: rgba(8, 251, 52, 0.944); /* Change border color on hover */
  }
  
  .header-1::after {
    content: '* Bulk Orders Only';
    position: absolute;
    bottom:0;
    /* right: 90vw; */
    left: 0;
    white-space: nowrap;
    color: #fff; /* Text color */
    font-size: 18px; /* Font size */
    padding: 5px; /* Padding around the text */
    z-index: 10; /* Ensure the text appears above other elements */
  }
  

.tiles-container {
    padding: 20px; /* Adjust padding as needed */
  }
  
  .tiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Adjust alignment as needed */
  }
  
  .tile {
    width: calc(100% - 20px); /* Adjust tile width */
    height: auto; /* Adjust tile height */
    margin-bottom: 20px; /* Adjust margin between tiles */
    background-color: #f0f0f0; /* Adjust tile background color */
    border-radius: 10px; /* Adjust border radius */
    padding: 20px; /* Adjust padding */
    text-align: center;
  }

  .services-icon  {
    width: 500px; /* Adjust image width */
    height: 400px; /* Adjust image height */
    margin: 0 20px; /* Adjust margin between images */
    border-radius: 10px; /* Adjust border radius */
  }
  
.tiles-container .tiles .services-icon:hover {
    /* transform: scale(1.1); */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
    opacity: 0.8;
    transition: filter 0.3s ease;
  }

  .order-icon {
    width: 400px; /* Adjust image width */
    height: 300px; /* Adjust image height */
    margin: 0 5px; /* Adjust margin between images */
    border-radius: 10px; /* Adjust border radius */
    transition: transform 0.3s ease-in-out;
  }

  
  .tile h2 {
    margin-bottom: 10px; /* Adjust margin as needed */
    font-size: 3.5rem;
  }
  
  .tile p {
    color: #116C65; /* Adjust text color as needed */
    font-size:1.5rem;
    margin: 30px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
  
  .tile .button {
    display: block;
    width: 100%;
    padding: 10px 20px;
    text-align: center;
    background-color: #116C65; /* Adjust button background color */
    color: #ffffff; /* Adjust button text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .tile .button:hover {
    background-color: #1ab0a4; /* Adjust button hover background color */
  }
  .app-link .app-container{
    border: 2px solid #116C65;
  }
  /* Adjustments for mobile screens */
 
@media only screen and (max-width: 768px) and (min-width:200px) {
  .header-1 {
    height: -40vh; /* Adjust container height for mobile */
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: center; /* Center elements vertically */
    align-items: center; /* Center elements horizontally */
  }
  .bg-image {
    width: 100%;
    max-height: 350px;
    background-repeat: repeat;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 98%;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    
  }
  
  .header-1 .header-2 {
    position: static; /* Remove absolute positioning */
    transform: none; /* Remove transformation */
    text-align: center;
  }
  .header-1 img {
    margin-top: 2px;
    max-width: 100%;
  }
  
  .header-1::after {
    content: '* Bulk Orders Only';
    position: absolute;
    bottom: 23px;
    font-size: 13px; /* Font size */
    padding: 5px; /* Padding around the text */
    z-index: 10; /* Ensure the text appears above other elements */
  }
  .button-container {
    gap: 0px; /* Add space between buttons */
  }
    .entry_button,
    .contact_button {
        width: 17vh; /* Adjust button width for mobile */
        height: 40px; /* Adjust button height for mobile */
        font-size: 1.2vh; /* Adjust button font size for mobile */
        margin: 10px; /* Adjust button margin for mobile */
    }
    
    .services-icon,
    .order-icon {
        width: 100%; /* Make images expand to screen width for mobile */
        height: auto; /* Maintain aspect ratio for mobile */
        margin: 10px 0; /* Adjust margin for mobile */
    }
  
    .tile {
        width: calc(100% - 40px); /* Adjust tile width for mobile */
    }
  
    .tile h2 {
        font-size: 2rem; /* Reduce heading font size for mobile */
    }
  
    .tile p {
        font-size: 1rem; /* Reduce paragraph font size for mobile */
        margin: 10px; /* Adjust margin for mobile */
    }
  
    .tile .button {
        padding: 8px 16px; /* Adjust button padding for mobile */
        font-size: 12px; /* Adjust button font size for mobile */
    }
    .header-2 {
      position: relative;
      top: 0%;
      left: 49%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: #000;
      white-space: nowrap;
      flex: 1;
      margin-top: -1rem;
      z-index: 3;
    }
    
    .header-2 h1 {
      font-size: 3vh; /* Adjust heading font size */
      align-items: center;
    }
    
    .header-2 p {
      font-size: 1.7vh; /* Adjust paragraph font size */
    }
    
}

@media only screen and (width: 360px) and (height:740px) {
  .header-1 {
    height: 50.5vh; /* Adjust container height for mobile */
  }
  .bg-image {
    width: 100%;
    max-height: 350px;
    background-repeat: repeat;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 94%;
    margin-top: 9px;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .header-1::after {
    content: '* Bulk Orders Only';
    position: absolute;
    bottom:35px;
    color: #fff; /* Text color */
    font-size: 13px; /* Font size */
    padding: 5px; /* Padding around the text */
    z-index: 10; /* Ensure the text appears above other elements */
  }
}


@media only screen and (width: 375px) and (height:667px) {
  .header-1 {
    height: 60vh; /* Adjust container height for mobile */
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: center; /* Center elements vertically */
    align-items: center; /* Center elements horizontally */
  }
  .bg-image {
    width: 100%;
    max-height: 350px;
    background-repeat: repeat;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 88%;
    margin-top: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
}
@media only screen and (width: 390px) and (height:844px) {
  .header-1 {
    height: 51vh; /* Adjust container height for mobile */
  }
  .bg-image {
    width: 100%;
    max-height: 350px;
    background-repeat: repeat;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 81.5%;
    margin-top: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-1::after {
    content: '* Bulk Orders Only';
    position: absolute;
    bottom:35px;
    color: #fff; /* Text color */
    font-size: 13px; /* Font size */
    padding: 5px; /* Padding around the text */
    z-index: 10; /* Ensure the text appears above other elements */
  }
}
@media only screen and (width: 430px) and (height:932px) {
  .header-1 {
    height: 46vh; /* Adjust container height for mobile */
  }
  .bg-image {
    width: 100%;
    max-height: 350px;
    background-repeat: repeat;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 82%;
    margin-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-1::after {
    content: '* Bulk Orders Only';
    position: absolute;
    bottom:35px;
    color: #fff; /* Text color */
    font-size: 13px; /* Font size */
    padding: 5px; /* Padding around the text */
    z-index: 10; /* Ensure the text appears above other elements */
  }
}
@media only screen and (width: 412px) and (height:915px) {
  .header-1 {
    height: 45.5vh; /* Adjust container height for mobile */
  }
  .bg-image {
    width: 100%;
    max-height: 350px;
    background-repeat: repeat;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 84%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-1::after {
    content: '* Bulk Orders Only';
    position: absolute;
    bottom:35px;
    color: #fff; /* Text color */
    font-size: 13px; /* Font size */
    padding: 5px; /* Padding around the text */
    z-index: 10; /* Ensure the text appears above other elements */
  }
}
@media only screen and (width: 412px) and (height:914px) {
  .header-1 {
    height: 45.5vh; /* Adjust container height for mobile */
  }
  .bg-image {
    width: 100%;
    max-height: 350px;
    background-repeat: repeat;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 85%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-1::after {
    content: '* Bulk Orders Only';
    position: absolute;
    bottom:35px;
    color: #fff; /* Text color */
    font-size: 13px; /* Font size */
    padding: 5px; /* Padding around the text */
    z-index: 10; /* Ensure the text appears above other elements */
  }
}
@media only screen and (width: 540px) and (height:720px) {
  .header-1 {
    height: 65.5vh; /* Adjust container height for mobile */
  }
  .bg-image {
    width: 100%;
    max-height: 410px;
    background-repeat: repeat;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 87%;
    margin-top: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-1::after {
    content: '* Bulk Orders Only';
    position: absolute;
    bottom:35px;
    color: #fff; /* Text color */
    font-size: 13px; /* Font size */
    padding: 5px; /* Padding around the text */
    z-index: 10; /* Ensure the text appears above other elements */
  }
}
@media only screen and (min-width: 769px) and (max-width: 819px) {
  .header-2 {
    position: relative;
    top:12%;
    left: 49%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #ffffff;
    padding-top: 0rem;
    white-space: nowrap;
    flex: 1;
  }
  .header-1::after {
   position: absolute;
   bottom: 10px;
  }
  .entry_button{
    width: 180px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 820px) and (max-width: 1024px) {
  .header-1::after {
    position: absolute;
    bottom: 5px;
   }
}

@media screen and (max-width: 768px) and (min-width:400px){
  .header-2 h1 {
    font-size: 4.2vh; /* Adjust heading font size */
    align-items: center;
  }
  
  .header-2 p {
    font-size: 2vh; /* Adjust paragraph font size */
    
  }
  .entry_button,
    .contact_button {
        width: 17vh; /* Adjust button width for mobile */
        height: 40px; /* Adjust button height for mobile */
        font-size: 1.3vh; /* Adjust button font size for mobile */
        margin: 10px; /* Adjust button margin for mobile */
    }
}
@media screen and (max-width: 768px) and (min-width:721px){
  .header-1 {
    height: -40vh; /* Adjust container height for mobile */
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: center; /* Center elements vertically */
    align-items: center; /* Center elements horizontally */
  }
  
  
  .header-1 .header-2 {
    position: static; /* Remove absolute positioning */
    transform: none; /* Remove transformation */
    text-align: center;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 99%;
    margin-top: 0px;
   
  }
  .header-1 img {
    margin-top: 2px;
    max-width: 100%;
  }
  
  .header-1::after {
    content: '* Bulk Orders Only';
    position: absolute;
    bottom: 23px;
    font-size: 15px; /* Font size */
    padding: 5px; /* Padding around the text */
    z-index: 10; /* Ensure the text appears above other elements */
  }
  
    .entry_button,
    .contact_button {
        width: 22vh; /* Adjust button width for mobile */
        height: 40px; /* Adjust button height for mobile */
        font-size: 1.9vh; /* Adjust button font size for mobile */
        margin: 10px; /* Adjust button margin for mobile */
    }
    
    .services-icon,
    .order-icon {
        width: 100%; /* Make images expand to screen width for mobile */
        height: auto; /* Maintain aspect ratio for mobile */
        margin: 10px 0; /* Adjust margin for mobile */
    }
  
    .tile {
        width: calc(100% - 40px); /* Adjust tile width for mobile */
    }
  
    .tile h2 {
        font-size: 2rem; /* Reduce heading font size for mobile */
    }
  
    .tile p {
        font-size: 1rem; /* Reduce paragraph font size for mobile */
        margin: 10px; /* Adjust margin for mobile */
    }
  
    .tile .button {
        padding: 8px 16px; /* Adjust button padding for mobile */
        font-size: 12px; /* Adjust button font size for mobile */
    }
  .bg-image {
  
    min-width: 150%;
    height: 100%;
    object-fit: cover;
    margin-right: 10rem;
    background-size: contain;
    margin-right: 30rem;
  } 
    .header-2 {
      position: relative;
      top: 50%;
      left: 49%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: #000;
      padding-top: 6rem;
      white-space: nowrap;
      flex: 1;
    }
    
    .header-2 h1 {
      font-size: 5vh; /* Adjust heading font size */
      align-items: center;
    }
    
    .header-2 p {
      font-size: 3vh; /* Adjust paragraph font size */
      
    }
    
}
@media only screen and (width: 768px) and (height: 1024px) {
  .header-1::after {
    position: absolute;
    bottom: 56px;
   }
   .header-1 {
    height: 40vh; /* Adjust container height for mobile */
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: center; /* Center elements vertically */
    align-items: center; /* Center elements horizontally */
  }
  .bg-image {
    width: 100%;
    height: 620px;
    background-repeat: repeat;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 86%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


@media only screen and (max-width: 768px) and (min-width: 700px) {
  .header-1::after {
    position: absolute;
    bottom: 40px;
   }
}
@media only screen and (max-width: 768px) and (min-width: 725px) {
  .header-1 {
    height: -40vh; /* Adjust container height for mobile */
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: center; /* Center elements vertically */
    align-items: center; /* Center elements horizontally */
  }
  .bg-image {
    width: 100%;
    height: 700px;
    background-repeat: repeat;
    object-fit: cover;
  }
  .header-1::after {
    position: absolute;
    bottom: 25px;
   }
}
