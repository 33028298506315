

/* Styles for the main container */
.process-container {
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px;
}

/* Styles for the header */
.process-container h1 {
  margin-bottom: 20px;
  color: #0074cc;
  font-size: 3vh;
}

/* Styles for the image */
.process-container .image img {
  max-width: 100%;
  height: auto;
}

/* Media query for responsiveness on smaller screens */
@media (max-width: 768px) {
  .process-container .image img {
    width: 100%;
    height: auto;
  }
}
