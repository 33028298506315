.chatbox-container {
  position: fixed;
  bottom: 50px; /* Raise the chatbox from the bottom by 50 pixels */
  right: 0;
  width: 400px; /* Adjust the width as needed */
  height: 80%; /* Set the height to 80% of the display height */
  max-height: calc(100% - 50px); /* Ensure the chatbox doesn't exceed the display height minus 50 pixels */
  background-color: #d4edda; /* Green background color */
  border: 2px solid #ccc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  z-index: 999; /* Ensure the chatbox appears above other content */
}

.loading-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #d4edda; /* Green background color */
  color: #155724; /* Dark green text color */
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.iframe-chatbox {
  flex: 1;
  width: 100%;
  height: 100%;
  margin-top: 40px;
}

.chat-close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 2px solid #000; /* Add a border to create a square frame */
  border-radius: 5px; /* Adjust as needed */
  font-size: 18px;
  color: #000;
  cursor: pointer;
  z-index: 1;
  display: flex; /* Use flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}


.chat-close-button:hover {
  background-color: #0074cc; /* Change background color to red on hover */
  color: #fff; /* Change text color to white on hover */
}

