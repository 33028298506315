.raw-materials-tile {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: wheat;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

 .image-block {
  max-width: 20%;
  flex-shrink: 0;
  text-align: right;
}

.image-block img {
  max-width: 100%;
  height: auto;

}

.content {
  flex: 2;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content h1 {
  font-size: 3rem; /* Increased font size to 2rem */
  margin: 0;
  text-align: center; /* Center-align the text */
  color: #333; /* Change the text color to maroon */
}

h3 {
  font-size: 2rem; /* Increased font size to 2rem */
  margin: 0;
  text-align: center; /* Center-align the text */
  color: #333; /* Change the text color to maroon */
}


.home-buttons {
  display: flex;
  justify-content: center; 
}

.home-button {
  background-color: #0077b6;
  color: white;
  padding: 10px 15px; /* Decrease the padding as needed */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  display: inline-block;
  font-weight: bold; /* Add bold font weight */
  text-transform: uppercase; /* Convert text to uppercase */
}

.button:first-child {
  margin-left: 0;
}

.button:hover {
  background-color: #005aa5;
}


.finance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: lightcyan;
  padding: 10px;
  margin-left: 100px;
  margin-right: 100px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.finance .image-container {
  max-width: 30%;
  flex-shrink: 0;
}

.finance .image-container img {
  max-width: 90%;
  height: auto;

}

.finance .content {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.finance h1 {
  font-size: 3rem;
  color: #0074cc;
  margin: 0;
}

.finance p {
  font-size: 1rem;
  margin: 20px;
}

/* machinery */
.machinery {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: beige;
  padding: 10px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 20px;
  margin-bottom: 50px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.machinery .image-container {
  max-width: 50%;
  flex-shrink: 0;
  text-align: right;
}

.machinery .image-container img {
  max-width: 100%;
  height: auto;

}


.machinery .content {
  flex: 2;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.machinery p {
  font-size: 1rem;
  margin: 20px;
}

.machinery h1 {
  color: maroon;
}

.app-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-container {
  text-align: center;
  border: 2px solid #0074cc; /* Border for the container */
  padding: 10px; /* Padding for the container */
  border-radius: 5px;
  margin-bottom: 20px;
}

.frame-header {
  font-size: 24px;
  margin-bottom: 20px;
}

.images-container {
  display: flex;
  justify-content: space-around; /* Adjust alignment as needed */
}

.app-icon {
  width: 200px; /* Adjust image width as needed */
  height: auto;
  margin: 0 15px; /* Adjust margins between images */
}


/* Common styles for multiple sections */
.content {
  padding: 2vw; /* Adjust padding using viewport width units */
}

h1,
h3,
p,
.finance h2,
.raw-materials-tile p,
.finance p,
.machinery p,
.button,
.finance .button,
.machinery .button {
  font-style: normal;
  text-align: center; /* Center-align text */
  font-weight: 500;
  color: #333;
}

/* Slideshow frame */
.slideshow-frame {
  position: relative;
  width: 350px; /* Set your desired width */
  height: 200px; /* Set your desired height */
  margin: 0 auto; /* Center horizontally */
  overflow: hidden;
text-align: center;
}

.slideshow-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slideshow-container img {
  width: auto;
  height: auto;
  object-fit: cover; /* Maintain aspect ratio and cover container */
  display: none;
}

/* Active image */
.slideshow-container img.active {
  display: block;
}

/* Dot container */
.dot-container {
  position: absolute;
  bottom: 1px; /* Adjust as needed */
  width: 100%;
  text-align: center;
}

/* Non-active dot */
.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #333; /* Set your desired non-active dot color */
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s ease; /* Optional: Add transition effect */
}

/* Active dot */
.dot.active-dot {
  background-color: #0074cc;
}
.slideshow-frame h2{
  font-size: 1.4rem;
  margin-top: 0.6vh;
  margin-bottom: 0.3vh;
}

/* For responsiveness */

@media screen and (max-width: 768px) {
  .raw-materials-tile,
  .finance,
  .machinery {
    flex-direction: column;
    align-items: center;
    margin: 0%;
  }

  .image-container {
    max-width: 50%;
  }

  .content {
    width: 100%;
    text-align: center;
    padding: 3vw; /* Adjust padding using viewport width units */
  }

  h1,
  h3,
  p,
  .finance h2,
  .finance p,
  .machinery p,
  .button,
  .finance .button,
  .machinery .button {
    font-size: 4vw; /* Adjust font sizes using viewport width units */
  }
}
.app-container {
  max-width: 90%; /* Set a maximum width for better mobile responsiveness */
  padding: 20px; /* Adjust padding for smaller screens */
}

.app-icon {
  max-width: 100%; /* Ensure images don't overflow their containers */
  height: auto;
  margin: 0 10px; /* Adjust margins between images */
}

