/* JobPage.css */

.job-page {
  display: flex;
  padding: 10px;
}

.header {
  background-color: #116C65;
  padding: 20px;
  margin-bottom: 20px; /* Add margin to the bottom of the header */
}

.header h1 {
  color: #fff;
  font-size: 2rem;
  padding: 30px;
}

.header p {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 10px; /* Add margin to the bottom of the subheading */
}


.job-page header {
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
}


.left-container {
  flex: 0 0 auto;
  width: 30%;
  padding: 20px;
  height: fit-content;
  border: 1px solid #ccc; /* Example border */
  margin: 10px;
}

.left-container h2 {
color: #fff;
font-size: 2rem;
text-align: center;
}

.left-container h3 {
  color: #333;
  font-size: 1.5;
  }
  


.left-container p {
  color: #0074cc;
  font-size: 1.1rem;
  }

  .right-block {
    flex: 1;
    width: 50%;
    padding: 20px;
    background-color: #eaeaea;
  }
  
.right-block h2 {
  color: #333;
  font-size: 2rem;
  text-align: center;
  }

.right-block p {
  color: #333;
  font: 1em sans-serif;
  }


  .job-card {
    width: calc(100% - 20px); /* Adjust the width to occupy available space */
    height: auto; /* Allow height to adjust based on content */
    margin: 10px auto; /* Center the card by setting left and right margin to auto */
    padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 3px solid red;
  }
  
  .job-card h3,
  .job-card p {
    margin: 0; /* Remove default margins */
  }
  

.job-card:hover {
  background-color: #eaeaea;
}

.job-details-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  background-color: #f9f9f9;
}

.job-details-container h3,
.job-details-container p,
.job-details-container button {
  text-align: left;
}

.job-details-container .label {
  font-size: 18px; /* Set the font size for labels */
  font-weight: bold; /* Optionally, set bold font for labels */
  margin-top: 10px; /* Adjust margin for spacing between label and data */
}

.job-form-container {
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  background-color: #f9f9f9;
}

/* Style for the Apply Now button */
.button-primary {
  background-color: #0074cc;
  color: #ffffff;
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

/* Style for the Back button */
.button-secondary {
  background-color: #069511;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

/* Optional hover effect */
.button-primary:hover, .button-secondary:hover {
  opacity: 0.8;
}


/* Mobile-specific styles */
@media screen and (max-width: 768px) {
  .job-page {
    flex-direction: column; /* Adjust to a column layout for mobile */
    align-items: center; /* Center-align items */
  }

  .left-container {
    width: 90%; /* Occupy 90% of the width */
    margin: 10px 0; /* Adjust margins for spacing */
  }

  .right-block {
    width: 90%; /* Occupy 90% of the width */
    margin-bottom: 20px; /* Adjust margins for spacing */
  }

  .job-card {
    width: 90%; /* Occupy full width */
    margin: 10px 0; /* Adjust margins for spacing */
    border: 3px solid red; /* Keep the border for visibility */
  }

  .job-details-container {
    width: 90%; /* Occupy 90% of the width */
    margin: 10px 0; /* Adjust margins for spacing */
  }
}
